import React, { useEffect, useState } from "react";
import "./Login.scss";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Icon, Input, InputLabel, Link } from "@material-ui/core";
import { useNetworkStatus, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useHistory } from "react-router-dom";
import { loginAction, microsoftLoginAction } from "examsbook-react-sdk/actions/loginAction";
import ReCAPTCHA from "react-google-recaptcha";
import LearnifyIcon from "../../assets/images/LearnifyLogoDark.png"
import { verifyDomainAction } from "examsbook-react-sdk/actions/verifyDomainAction";
import { getSettingsAction } from "examsbook-react-sdk/actions/getSettingsAction";
import { ColorsConstant } from "../../constants/colorConstant";
import MicrosoftLogin from "react-microsoft-login";
import { setSubdomain } from "examsbook-react-sdk/actions/getSettingsAction";

const Login = () => {
  const [googleRecaptchaToggle, setGoogleRecaptchaToggle] = useState("");
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState(null);
  const [formValues, setFormValues] = useState({username: "", password: ""});
  const [microsoftLoginSiteKey, setMicrosoftLoginSiteKey] = useState(false);
  const [clientId, setClientId ] = useState("");
  const [redirectURI, setRedirectURI] = useState("");
  const [googleRecaptchaSiteKey, setGoogleRecaptchaSiteKey] = useState(null);
  const [bgColor, setBgColor] = useState(null);
  const [textColor, setTextColor] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);  
  const [isLoading, setIsLoading] = useState(false);  
  const [dynamicLogo, setDynamicLogo] = useState(null);
  const [settingsData, setSettingsData] = useState([]);
  const [subdomainName, setSubDomainName] = useState('');
  const dispatch = useDispatch();
  const t = useTranslations();
  const history = useHistory();
  const isMobileNumber = (str) => /^\d{10}$/.test(str);
  const containsOnlyNumbers = (str) => /^\d+$/.test(str);
  let inputValue;
  const isOnline = useNetworkStatus();
  const [microsoftLoginError, setMicrosoftLoginError] = useState("");

  let subdomain = useSelector(state => state?.subdomainReducer?.subdomain) || subdomainName;

  useEffect(() => {
    getSubdomain();
    queryParamImpersonate();
  }, []);

  useEffect(() => {
      getSettingsApiData();
  }, [subdomain]);

  const queryParamImpersonate = () => {
    let token = new URLSearchParams(window.location.search).get('token');
    let impersonating = '';
    if(token) {
      try {
        const jsonString = atob(token);
        const parsedData = JSON.parse(jsonString);
        token = parsedData.token;
        impersonating = parsedData.impersonating;
        localStorage.setItem('token', token);
        localStorage.setItem('impersonating', impersonating);
        history.push('/dashboard');
      } catch (error) {
        console.log('Error parsing token:', error);
      }
    }
  }

  const getSettingsApiData = async () => {
    try {
      await dispatch(getSettingsAction(subdomain)).then(
        (res) => {
          setSettingsData(res?.data);
          setIsLoading(true);
        }
      );
    } catch (error) {
      console.error("Error -", error.message);
    }
  };

  const handleChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  }

  const getSubdomain = () => {
    const host = window.location.host;
    const domainParts = host.split('.');
    if (process.env.REACT_APP_TENANT_DOMAIN) {
      setSubDomainName(process.env.REACT_APP_TENANT_DOMAIN);
      dispatch(setSubdomain(process.env.REACT_APP_TENANT_DOMAIN));
    }
    else if (domainParts.length > 3) {
      const domainName = domainParts[0];
      setSubDomainName(domainName);
      dispatch(setSubdomain(domainName));
    }
  };

  const handleDomainVerify = async (e) => {
    e.preventDefault();
    const { domainname } = formValues;

    const domainVerifyData = {
      subdomain: domainname,
      url: window.location.href,
    };
    try {
      dispatch(verifyDomainAction(domainVerifyData)).then((res) => {
        if (res?.isVerify && res?.url && res.success === "success") {
          window.location.href = res.url;
        }
        if (!res) {
          document.getElementById('domain-name-error').innerHTML = `<div class="errorMsg">Invalid domain</div>`;
          document.getElementById('domain-name-error').style.display = 'block';
          return;
        }
      })
    }
    catch (error) {
      console.log('Error -', error.message);
      document.getElementById("domain-name-error").innerHTML = `<div class="errorMsg">An unexpected error occurred. Please try again later.</div>`;
      document.getElementById("domain-name-error").style.display = "block";
      return;
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    const { username, password } = formValues;

    if (googleRecaptchaToggle === true && !googleRecaptchaToken) {
      const resetPasswordError = document.getElementById('reset-password-error');
      if (resetPasswordError) {
        resetPasswordError.innerHTML = `<div class="errorMsg">Please validate the captcha first.</div>`;
        resetPasswordError.style.display = 'block';
      }
      return;
    }


    const loginData = {
      password,
      subdomain: subdomainName,
      [isNaN(+username) ? 'email' : 'mobileNumber']: username,
      ...(googleRecaptchaToggle === true && { captcha: googleRecaptchaToken }),
    };
    try {
      dispatch(loginAction(loginData)).then((res) => {
        if (res?.newPasswordRequired) {
          history.push('/change-password', loginData);
          return;
        }
        if (res?.token) {
          localStorage.setItem('token', res?.token);
          history.push('/dashboard');
          return;
        }
        if (res?.status === 400) {
          document.getElementById('reset-password-error').innerHTML = `<div class="errorMsg">${res?.data?.message}</div>`;
          document.getElementById('reset-password-error').style.display = 'block';
          document.getElementById('changePasswordBtn').setAttribute("disabled", "disabled");
          return;
        }
      })
    }
    catch (error) {
      console.log('Error -', error.message);
    }
  }

  useEffect(() => {
    const dynamicLogos = settingsData?.data?.[0]?.branding?.logos?.webAndAppLogo?.imagePath;
    setDynamicLogo(dynamicLogos ? dynamicLogos : LearnifyIcon);
    setGoogleRecaptchaToggle(settingsData?.data?.[0] ? settingsData?.data?.[0]?.integration?.recaptcha?.googleRecaptcha : false);
    setGoogleRecaptchaSiteKey(settingsData?.data?.[0] ? settingsData?.data?.[0]?.integration?.recaptcha?.siteKey : '')
    setBgColor(settingsData?.data?.[0] ? settingsData?.data?.[0]?.branding?.backgroundAndTextTheme?.primaryBackgroundColor : ColorsConstant.defaultBlue);
    setTextColor(settingsData?.data?.[0] ? settingsData?.data?.[0]?.branding?.backgroundAndTextTheme?.primaryTextColor : ColorsConstant.white);
    setClientId(settingsData?.data?.[0]?.integration?.singleSignOn?.clientId);
    setRedirectURI(settingsData?.data?.[0]?.integration?.singleSignOn?.redirectURI);
    setMicrosoftLoginSiteKey(settingsData?.data?.[0]?.integration?.singleSignOn?.microsoft);
  },[isLoading, settingsData]);

  // handle autofill scenario
  useEffect(() => {
    const emailInput = document.getElementById('loginEmail');
    if (emailInput) {
      handleEmailBlur({ target: emailInput });
    }
  }, []);

  const handleEmailChange = (event) => {
    inputValue = event.target.value.toLowerCase();
    handleChange('username', inputValue);
  };
  
  const handleEmailBlur = (event) => {
    const inputValue = event.target.value.trim();
  
    const isValidEmail = (email) =>
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.toLowerCase());
    let errMsg = "";
    let emailValid = true;
  
    if (inputValue) {
      if (containsOnlyNumbers(inputValue)) {
        if (!isMobileNumber(inputValue)) {
          emailValid = false;
          errMsg = "Enter Valid Mobile Number";
        }
      } else {
        if (!isValidEmail(inputValue)) {
          emailValid = false;
          errMsg = "Enter Valid Email";
        }
      }
    }

    if (!emailValid) {
      document.getElementById('reset-password-error').innerHTML = `<div class="errorMsg">${errMsg}</div>`;
      document.getElementById('reset-password-error').style.display = 'block';
      document.getElementById('changePasswordBtn').setAttribute("disabled", "disabled");
    } else {
      document.getElementById('reset-password-error').style.display = 'none';
      document.getElementById('changePasswordBtn').removeAttribute("disabled");
    }

  };

  const handleDomainName = (event) => {
    inputValue = event.target.value;
    handleChange('domainname', inputValue);
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    history.push('/forgot-password');
  };

  const microsoftAuthHandler = (err, data) => {  
    if (err) {
      if (err.errorCode === "user_cancelled") {
        setMicrosoftLoginError("Microsoft login cancelled by user");
      } else setMicrosoftLoginError(err.errorMessage);
      return;
    }
    if (data && data?.accessToken) {
      const accessToken = data?.accessToken  
      dispatch(microsoftLoginAction({ accessToken, subdomain: subdomainName })).then((res) => {
        if (res?.token) {
          localStorage.setItem("token", res?.token);
          history.push("/dashboard");
        } else {
          setMicrosoftLoginError(res?.data?.message || "Login failed")
        }
      });
    }
  };

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let errMsg = "Enter min 8 letters, with at least a symbol, upper and lower case letters and a number";
    const captchaContainer = document.getElementById("captchaContainer");
    const loginError = document.getElementById("login-error");
    const loginButton = document.getElementById("login-button");
    handleChange('password', password);
    handleActiveLoginButton(formValues.username, password, googleRecaptchaToken);

    let passValid = true;
    if (!regex.test(event.target.value)) {
      errMsg = "Enter min 8 letters, with at least a symbol, upper and lower case letters and a number";
      passValid = false;
    }

    if (!passValid) {
      document.getElementById('reset-password-error').innerHTML = `<div class="errorMsg">${errMsg}</div>`;
      document.getElementById('reset-password-error').style.display = 'block';
      document.getElementById('changePasswordBtn').setAttribute("disabled", "disabled");
    } else {
      document.getElementById('reset-password-error').style.display = 'none';
      document.getElementById('changePasswordBtn').removeAttribute("disabled");
    }

    if (password === "12345") {
      if (googleRecaptchaToggle === true && captchaContainer) {
        captchaContainer.style.display = "none";
      }
      setPasswordError("");
      setIsButtonDisabled(false);
    }
    if(password.length == 6 && isMobileNumber(formValues.username)) {
      setPasswordError("");
      setIsButtonDisabled(false);
      document.getElementById('reset-password-error').style.display = 'none';
    }
    else {
      if (!regex.test(password)) {
        setPasswordError(errMsg);
        setIsButtonDisabled(true);
      } else {
        if (googleRecaptchaToggle === true && captchaContainer) {
          captchaContainer.style.display = "block";
        }
        setPasswordError("");
        setIsButtonDisabled(false);
      }
    }

    // Update DOM elements dynamically for non-React-managed attributes
    if (loginError) {
      if (passwordError) {
        loginError.innerHTML = `<div class="errortext">${passwordError}</div>`;
        loginError.style.display = "block";
      } else {
        loginError.style.display = "none";
      }
    }

    if (loginButton) {
      if (isButtonDisabled) {
        loginButton.setAttribute("disabled", "disabled");
      } else {
        loginButton.removeAttribute("disabled");
      }
    }
  };

  const togglePasswordVisibility = (inputId, iconId) => {
    setShowPassword((prev) => !prev); // This updates the React state
    const passwordInput = document.getElementById(inputId);
    const icon = document.getElementById(iconId);
  if (passwordInput) {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        return icon?.classList?.replace("fa-eye-slash", "fa-eye");
      } else {
        passwordInput.type = "password";
        return icon?.classList?.replace("fa-eye", "fa-eye-slash");
      }
    } else {
      console.error(`Input element with id "${inputId}" not found.`);
    }
  };

  const preventSpaceKey = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  };
  const handleRecaptchaChange = (token) => {
    setGoogleRecaptchaToken(token);
    handleActiveLoginButton(formValues.username, formValues.password, token);
  };

  const handleActiveLoginButton = (username, password, recaptchaToken) => {
    const isValid = username && password && recaptchaToken;
    setIsButtonDisabled(!isValid);
  };

    return (
       <>
        <Grid className="login-parent-div">
          <Grid className="login-child-div">
            {isLoading && dynamicLogo && <Box component="img" id="login-img-learn" className="login-logo" src={dynamicLogo} alt="Learnify" />}
          </Grid>
          {!isOnline && (
            <Box className="form-group error-msg" style={{ display: 'block' }}>
              {t('No internet connection!')}
            </Box>
          )}
          {microsoftLoginError && (
            <Box className="form-group error-msg" style={{ display: 'block' }}>
              {microsoftLoginError}
            </Box>
          )}
          {!subdomainName &&
          // domain verification screen
          <Grid className="login-right-side-box">
          <Box className="verify-domain" style={{ color: `${bgColor}` }}>{t('Verify Domain')}</Box>
          <Box className="form-group error-msg" id="domain-name-error"></Box>
          <Box component="form" id="form-login" name="loginForm" className="form-input-data form-horizontal" onSubmit={handleDomainVerify} method="post">
            <Box>
              <Box className="mdc-text-field mdc-text-field--outlined email-field">
                <Box 
                sx={{display: 'flex', alignItems: 'center', fontSize: '14px', gap: '8px', justifyContent: 'center'}}>
                  <Input type="text" value={formValues.domainname} className="domain-input-box input-box required_text" id="domainField" autoComplete="off" placeholder="Domain name" onChange={(e) => handleDomainName(e)} onKeyDown={preventSpaceKey} />
                  <Box className="domain-name">.{window.location.host}</Box>
                </Box>
              </Box>
              <Box className="help-block with-errors"></Box>
            </Box>
              <Box className="parent-login-button-div change-domain-name-submit-btn">
               <Button
                  type="submit"
                  id="changePasswordBtn"
                  className={`login-new-btn ${!formValues?.domainname ? 'disabled-btn' : ''}`}
                  style={{backgroundColor: `${bgColor}`, color: `${textColor}`}}
                  disabled={!formValues?.domainname}
                >
                    {t('Verify')}
                </Button>
              </Box>
          </Box>
          </Grid>
          }
          {subdomainName && <Grid className="login-right-side-box">
          <Box className="login-title" style={{ color: `${bgColor}` }}>{t('Login')}</Box>
          <Box className="form-group error-msg" id="reset-password-error"></Box>
          <Box component="form" id="form-login" name="loginForm" className="form-horizontal" onSubmit={handleLogin} method="post">
            <Box>
              <Box className="mdc-text-field mdc-text-field--outlined email-field">
                <Box className="mdc-notched-outline__notch">
                  <InputLabel style={{color: bgColor}} className="mdc-text-field__label" htmlFor="loginEmail"
                    >{t('Email/Mobile No.')}</InputLabel>
                </Box>
                <Input type="text" value={formValues.username} className="input-box required_text" id="loginEmail" autoComplete="off" placeholder="Email/Mobile No." onChange={handleEmailChange} onBlur={handleEmailBlur} onKeyDown={preventSpaceKey} />
              </Box>
              <Box className="help-block with-errors"></Box>
            </Box>
            <Box style={{position: 'relative'}}>
              <Box className="mdc-text-field mdc-text-field--outlined removeTopGap">
                <InputLabel style={{color: bgColor}} className="mdc-text-field__label" htmlFor="loginPassword">{t('Password')}</InputLabel>
                <Input type={showPassword ? "text" : "password"} value={formValues.password} className="input-box required_text"
                id="loginPassword" autoComplete="off" placeholder="Password" onChange={handlePasswordChange} onKeyDown={preventSpaceKey} />
                <Box className="mdc-notched-outline">
                  <Box className="mdc-notched-outline__leading"></Box>
                  <Box className="mdc-notched-outline__trailing"></Box>
                </Box>
                <Icon className="fa fa-eye-slash toggle-password-icon" id="toggleLoginPassword" onClick={() => togglePasswordVisibility('loginPassword', 'toggleLoginPassword')}></Icon>
              </Box>
              <Box className="help-block with-errors"></Box>
            </Box>
            <Box className="login-forgot-password-div">
              <Link id="forgot-password" onClick={handleForgotPassword} className="login-forgot-password-text" style={{color: bgColor}} >{t('Forgot Password')}</Link>
            </Box>
              {googleRecaptchaToggle === true &&
                <ReCAPTCHA id="captchaContainer" className="g-recaptcha" sitekey={googleRecaptchaSiteKey} onChange={handleRecaptchaChange} />
              }
              <Box className="parent-login-button-div">
               <Button
               type="submit"
               id="changePasswordBtn"
               className={`login-new-btn ${isButtonDisabled ? 'disabled-btn' : ''}`}
              style={{backgroundColor: `${bgColor}`, color: `${textColor}`}}
               disabled={isButtonDisabled} // Add the disabled attribute
           >
               {t('Login')}
           </Button>
           {
            microsoftLoginSiteKey && <MicrosoftLogin
            clientId={clientId}
            authCallback={microsoftAuthHandler}
            usePopup={true}
            children={null}
            redirectUri={redirectURI}
            responseType="code"/>
           }
              </Box>
          </Box>
          </Grid>}
        </Grid>
       </>
    );
};

export default Login;