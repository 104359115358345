import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import status from "../constants/actionStatus";
import { getSettingsAction } from "../actions/getSettingsAction";

/**
 * get list of all category.
 * @returns {*}
 */

export default function useSettings(user) {
    const subdomain = useSelector(state => state?.subdomainReducer?.subdomain) || localStorage.getItem('subdomain');
    const dispatch = useDispatch();
    useEffect(() => {
        if (user && subdomain) {
            dispatch(getSettingsAction(subdomain));
        }
    }, [user, subdomain]);

    const settings = useSelector((state) => state.settingsReducers);
    const isLoadingSettings = settings.status === status.PENDING;
    const settingsData = settings.settingsData?.data || [];

    return {
        isLoadingSettings,
        settingsData,
    };
}