import { stopImpersonation } from "../services/stopImpersonateService.js";

export const stopImpersonate = (data) => (dispatch) => {
    dispatch(request());
    return stopImpersonation(data)
        .then((response) => {
            // handle success
            return dispatch(success(response));
        })
        .catch((error) => {
            console.log("error", error);
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "STOP_IMPERSONATE_REQUEST" };
    }

    function success(response) {
        return { type: "STOP_IMPERSONATE_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "STOP_IMPERSONATE_FAILURE", payload: error };
    }
};
