import { genericLogin } from "../services/loginService";
import { microsoftLogin } from "../services/microsoftLoginService";

export const loginAction = (data) => (dispatch) => {
    dispatch(request());
    return genericLogin(data)
        .then((response) => {
            // handle success
            dispatch(success(response));
            return response;
        })
        .catch((error) => {
            console.log("error", error);
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "LOGIN_REQUEST" };
    }

    function success(response) {
        return { type: "LOGIN_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "LOGIN_FAILURE", payload: error };
    }
};

export const microsoftLoginAction = (data) => (dispatch) => {
    dispatch(request());
    return microsoftLogin(data)
        .then((response) => {
            // handle success
            dispatch(success(response));
            return response;
        })
        .catch((error) => {
            console.log("error", error);
            // handle error
            dispatch(failure(error.toString()));
        });
 
    function request() {
        return { type: "MICROSOFT_LOGIN_REQUEST" };
    }
 
    function success(response) {
        return { type: "MICROSOFT_LOGIN_SUCCESS", payload: response };
    }
 
    function failure(error) {
        return { type: "MICROSOFT_LOGIN_FAILURE", payload: error };
    }
};
