/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslations, useStyles, useCourseContent } from "examsbook-react-sdk/custom-hooks";
import TabContainer from "./TabContainer";
import { pdfjs } from "react-pdf";
import SelfLearningContent from './SelfLearningContent/SelfLearningContent';
import { ReactComponent as EvaluationNoTest } from "../../assets/images/evaluation-No-Test.svg";
import "./SelfLearning.scss";
import InstructionBlock from '../Quiz/InstructionBlock/InstructionBlock';
import LearningText from '../SelfLearningText/LearningText';
import { useDispatch } from 'react-redux';
import { courseCertificateListAction } from 'examsbook-react-sdk/actions/certificateAddResponse';
import moment from 'moment';
import { Viewer, Worker } from'@react-pdf-viewer/core';
import'@react-pdf-viewer/core/lib/styles/index.css';


/**
 * Course Material Container.
 * @param props
 * @returns {*}
 * @constructor
 */

function TabsLandingPage(props) {
    const {
        subjectDetail,
        selectedIndex,
        tabBarList,
        selectedTab,
        handleTabChange,
        onQuizCardClick,
        onPdfOpen,
        setdownloadCertificateButton,
        history,
        course_id,
        subject_id,
        topic_id,
        goToDashboard,
        showGreen,
        downloadCertificateButton,
        onVideoRefresh,
        videoRefresh,
        sectionChanged,
        setSectionChanged,
        quizAttemptForResult,
        quizAttempCourseId,
        coursePercentage,
        pdfOpen,
        videoProgress,
        handleVideoProgress,
        item,
        showEvaluationTest,
        setShowEvaluationTest,
        courseName,
        selectedQuizCard,
        openpdf,
        handleClosePdf,
        setSectionNumber,
        setSectionName,
        setNextClick,
        setIsDropDown,
        setCourseDataList,
        pdfPath,
        setpdfPath,
        handleUpdateCourseProgress,
        selectedSubmenu,
        setSelectedSubmenu,
        currentSectionIndex,
        setCurrentSectionIndex,
        currentDataIndex,
        setCurrentDataIndex,
        setIsDropdownOpen,
        isDropdownOpen,
        mySectionData,
        setMySectionData,
        openPdfViewer,
        processSectionData,
    } = props;
    const t = useTranslations();
    let coursedatacontent = '';
    const dispatch = useDispatch();
    const classes = useStyles(styles)();
    const [contentData, setContentData] = useState('');
    const [sectionData, setSectionData] = useState('');
    const [showComponent, setShowComponent] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [certificateUnLocked, isCertificateUnlocked] = useState(false)
    const [quizIsStarted, setQuizStarted] = useState(false);
    const [showInstructionBox, setInstructionBox] = useState(true);
    const handleRefresh = () => setRefresh(!refresh);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [certificateData, setCertificateData] = useState([])
    const [complete, setComplete] = useState(false);

    const { content, isLoading } = useCourseContent(course_id, subject_id, topic_id, refresh);
    const { data: courseDetail } = content;

    const [isError, setIsError] = useState("");

    const [recordedVideos, setRecordedVideos] = useState([]);
    const [quiz, setQuiz] = useState([]);
    const [pdfNotes, setPdfNotes] = useState([]);

    const completedTest = quiz?.filter(function (item) {
        return item?.attempted;
    });
    const pendingTest = quiz?.filter(function (item) {
        return !item?.attempted;
    });



    const closePdfViewer = () => {
        setpdfPath('');
        setNumPages(null);
        setPageNumber(1)
        handleClosePdf();
    }
    const getCourseSectionData = async () => {
        try {
          if (course_id !== undefined) {
            if (!props.activeItem && typeof mySectionData != "undefined") {
                props.setActiveItem({
                    data:mySectionData?.data?.section[0],
                    sectionData:mySectionData?.data
                });
            }
          }
        } catch (error) {
            setIsError(error.message);
        }
    };
    useEffect(() => {
        getCourseSectionData();
    }, [course_id]);

    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; });
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const handleDownloadPdf = (url, filename) => {
        const anchor = document.createElement('a');
        anchor.href = url;
        // anchor.download = 'notes.pdf';
        anchor.download = `${filename}.pdf`;
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])

    useEffect(() => {
        if (courseDetail && courseDetail.length && courseDetail[0].recordedVideos && courseDetail[0].recordedVideos.length) {
            setRecordedVideos(courseDetail[0].recordedVideos);
        } else {
            setRecordedVideos([]);
        }
        if (mySectionData && mySectionData?.data?.evaluationTest) {
            setQuiz(mySectionData.evaluationTest);
        } else {
            setQuiz([]);
        }
        if (courseDetail && courseDetail.length && courseDetail[0].pdfNotes && courseDetail[0].pdfNotes.length) {
            setPdfNotes(courseDetail[0].pdfNotes);
        } else {
            setPdfNotes([]);
        }
    }, [courseDetail]);

    useEffect(() => {
        if (course_id !== undefined) {
            fetchData();
        }
    }, [course_id, props?.videoRefresh]);

    const fetchData = async () => {
        try {
            if (mySectionData.data.section !== undefined) {
                mySectionData.data.section.forEach(item => {
                    setSectionData(item)
                    const value = item.courseSectionData.find(subValue => subValue.refModel === "RecordedVideo");
                    if (item._id) {
                        setContentData(value)
                    }
                });
            }
        } catch (error) {
            setIsError(error.message);
        }
    };

    if(mySectionData && mySectionData?.data?.evaluationTest && mySectionData?.data?.evaluationTest?.length > 0) {
        var passingPercent = mySectionData?.data?.evaluationTest[0]?.testId?.passingPercent;
        var quizEndDate = moment(mySectionData?.data?.evaluationTest[0]?.testId?.endDate).format("DD-MM-YYYY HH:mm:ss");
    }
    function toggleInstructionBox() {
        setInstructionBox(!showInstructionBox);
    }

    async function startQuiz() {
        await getCourseSectionData();
        setQuizStarted(true);
        setInstructionBox(false);
        props.setStartButtonClicked(true);
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowComponent(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (course_id) {
            dispatch(courseCertificateListAction(course_id)).then((res) => {
                if (res?.data?.length) {
                    setCertificateData(res?.data[0])
                }
            });
        }
    }, [course_id]);
    const checkEvaluationUnlockPercent = (quizData) => {
        return quizData?.EvaluationUnlockPercent
    }

    const checkUnlockEvaluationTest = (quizData) => {
        if (Math.round(coursePercentage) >= quizData?.EvaluationUnlockPercent) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            {/* {selectedIndex === 0 && */}
            {
                <TabContainer className={classes.contentBox}>
                    {showEvaluationTest ?
                        item?.refModel === "RecordedVideo" ?
                            <TabContainer className={classes.contentBox}>
                                <SelfLearningContent
                                    videoProgress={videoProgress}
                                    mySectionData={mySectionData}
                                    setMySectionData={setMySectionData}
                                    handleVideoProgress={handleVideoProgress}
                                    activeItem={item}
                                    selectedCourseId={course_id}
                                    pdfOpen={pdfOpen}
                                    openPdfViewer={openPdfViewer}
                                    videoRefresh={videoRefresh}
                                    onVideoRefresh={onVideoRefresh}
                                    certificateUnlocked={certificateData?.isCompleted}
                                    showEvaluationTest={showEvaluationTest}
                                    setShowEvaluationTest={setShowEvaluationTest}
                                    courseName={courseName}
                                    startQuiz={startQuiz}
                                    showInstructions={props?.showInstructions}
                                    showInstructionScreen={props?.showInstructionScreen}
                                    subjectDetail={props?.subjectDetail}
                                    sectionName={props?.sectionName}
                                    sectionId={props?.activeItem?.data?._id}
                                    handleUpdateCourseProgress={handleUpdateCourseProgress}
                                    setSectionNumber={setSectionNumber}
                                    setSectionName={setSectionName}
                                    setNextClick={setNextClick}
                                    setIsDropDown={setIsDropDown}
                                    setCourseDataList={setCourseDataList}
                                    selectedSubmenu={selectedSubmenu}
                                    setSelectedSubmenu={setSelectedSubmenu}
                                    currentSectionIndex = {currentSectionIndex}
                                    setCurrentSectionIndex = {setCurrentSectionIndex}
                                    currentDataIndex = {currentDataIndex}
                                    setCurrentDataIndex={setCurrentDataIndex}
                                    isDropdownOpen={isDropdownOpen}
                                    setIsDropdownOpen={setIsDropdownOpen}
                                    processSectionData={processSectionData}
                                    
                                />
                            </TabContainer>
                            : item?.refModel === "TextNotes" ?
                            <TabContainer className={classes.contentBox}>
                          <LearningText
                            textData={item?.contentData}
                            textName={item?.name}
                          />
                            </TabContainer>
                            : item?.refModel === "Quiz" ?
                                <TabContainer className={classes.contentBox}>
                                    {!item?.contentData && showComponent &&
                                        <div className={classes.divcertificate}>
                                            <EvaluationNoTest className={classes.imgAlign} />
                                            <p className={classes.textHeading}>{t('No Test Found!')}</p>
                                            <p className={classes.textPara}>{t('Apologies, but there are currently no evaluation')}<br />{t('tests available for display.')}</p>
                                        </div>
                                    }

                                    {item?.contentData && <InstructionBlock quiz={item?.contentData}
                                        quizIsStarted={quizIsStarted}
                                        toggleInstructionBox={toggleInstructionBox}
                                        startQuiz={startQuiz}
                                        quizAttemptForResult={quizAttemptForResult}
                                        quizAttempCourseId={quizAttempCourseId}
                                        unlockEvaluationTest={checkUnlockEvaluationTest(item?.contentData)}
                                        evaluationUnlockPercentage={checkEvaluationUnlockPercent(item?.contentData)}
                                        certificateUnlocked={certificateData?.isCompleted}
                                        quizEndDate={quizEndDate}
                                        startButtonClicked={props.startButtonClicked}
                                        selectedQuizCard={item?.contentData}
                                        courseName={courseName}
                                        mySectionData={mySectionData}
                                        setMySectionData={setMySectionData}
                                    />}
                                </TabContainer>
                                : item?.refModel === "Questions" ?
                                <TabContainer className={classes.contentBox}>
                                    {item?.contentData && <InstructionBlock quiz={item?.contentData}
                                        courseName={item?.name}
                                        mySectionData={mySectionData}
                                        setMySectionData={setMySectionData}
                                        handleUpdateCourseProgress={handleUpdateCourseProgress}
                                    />}
                                </TabContainer>
                                : item?.refModel === "PdfNotes" ?(
                                    pdfPath ? (
                                        <div className={classes.pdfContainer}>
                                            <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`}>
                                                <Viewer fileUrl={pdfPath} />
                                            </Worker>
                                        </div>
                                    ) : ( <>{t("Failed_to_load_pdf_file")}</> ))
                                    : <></>

                        : props.showInstructions ?
                            <InstructionBlock quiz={mySectionData?.data?.evaluationTest[0]?.testId}
                                quizIsStarted={quizIsStarted}
                                toggleInstructionBox={toggleInstructionBox}
                                startQuiz={startQuiz}
                                mySectionData={mySectionData}
                                setMySectionData={setMySectionData}
                                quizAttemptForResult={quizAttemptForResult}
                                quizAttempCourseId={quizAttempCourseId}
                                unlockEvaluationTest={checkUnlockEvaluationTest(mySectionData?.data?.evaluationTest[0]?.testId)}
                                evaluationUnlockPercentage={checkEvaluationUnlockPercent(mySectionData?.data?.evaluationTest[0]?.testId)}
                                certificateUnlocked={certificateData?.isCompleted}
                                quizEndDate={quizEndDate}
                                startButtonClicked={props.startButtonClicked}
                                selectedQuizCard={mySectionData}
                                courseName={courseName}
                            /> : <SelfLearningContent
                                selectedIndex={selectedIndex}
                                videoProgress={videoProgress}
                                mySectionData={mySectionData}
                                setMySectionData={setMySectionData}
                                handleVideoProgress={handleVideoProgress}
                                activeItem={props.activeItem}
                                setActiveItem={props.setActiveItem}
                                selectedCourseId={course_id}
                                openPdfViewer={openPdfViewer}
                                pdfOpen={pdfOpen}
                                videoRefresh={videoRefresh}
                                onVideoRefresh={onVideoRefresh}
                                certificateUnlocked={certificateData?.isCompleted}
                                coursePercentage={coursePercentage}
                                tabBarList={tabBarList}
                                onPdfOpen={onPdfOpen}
                                selectedTab={selectedTab}
                                subject_id={subject_id}
                                topic_id={topic_id}
                                handleTabChange={handleTabChange}
                                setSectionChanged={setSectionChanged}
                                onQuizCardClick={onQuizCardClick}
                                setSectionNumber={setSectionNumber}
                                setSectionName={setSectionName}
                                sectionId={props?.activeItem?.data?._id}
                                handleUpdateCourseProgress={handleUpdateCourseProgress}
                                setNextClick={setNextClick}
                                setIsDropDown={setIsDropDown}
                                setCourseDataList={setCourseDataList}
                                setdownloadCertificateButton={setdownloadCertificateButton}
                                downloadCertificateButton={downloadCertificateButton}
                                quizIsStarted={quizIsStarted}
                                toggleInstructionBox={toggleInstructionBox}
                                startQuiz={startQuiz}
                                quizAttemptForResult={quizAttemptForResult}
                                quizAttempCourseId={quizAttempCourseId}
                                unlockEvaluationTest={checkUnlockEvaluationTest(item?.contentData)}
                                evaluationUnlockPercentage={checkEvaluationUnlockPercent(item?.contentData)}
                                quizEndDate={quizEndDate}
                                startButtonClicked={props.startButtonClicked}
                                selectedQuizCard={selectedQuizCard}
                                showComponent={showComponent}
                                certificateData={certificateData}
                                showEvaluationTest={showEvaluationTest}
                                setShowEvaluationTest={setShowEvaluationTest}
                                courseName={courseName}
                                showInstructions={props?.showInstructions}
                                showInstructionScreen={props?.showInstructionScreen}
                                subjectDetail={props?.subjectDetail}
                                sectionName={props?.sectionName}
                                selectedSubmenu={selectedSubmenu}
                                setSelectedSubmenu={setSelectedSubmenu}
                                currentSectionIndex = {currentSectionIndex}
                                setCurrentSectionIndex = {setCurrentSectionIndex}
                                currentDataIndex = {currentDataIndex}
                                setCurrentDataIndex={setCurrentDataIndex}
                                isDropdownOpen={isDropdownOpen}
                                setIsDropdownOpen={setIsDropdownOpen}
                                processSectionData={processSectionData}
                            />}
                </TabContainer>}
        </>
    );
}

const styles = {
    contentBox: {
        "& div": {
            "& div": {
                minHeight: '0px'
            }
        },
    },
    pdfContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    textPara: {
        fontWeight: '400',
        fontSize: '14px',
        color: '#84818A',
        textAlign: 'center',
        margin: '0px',
        '@media (max-width: 600px)': {
            fontSize: '11px',
        },
    },

    textHeading: {
        fontWeight: '700',
        fontSize: '32px',
        margin: '0px',
        '@media (max-width: 600px)': {
            fontSize: '20px'
        },
    },

    imgAlign: {
        marginTop: 'calc(10vh - 50px)',
        '@media (max-width: 425px)': {
            height: '17rem'
        },
        '@media (max-width:959px)': {
            height: '12.7rem'
        }
    },

    divcertificate: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3rem'
    },
    '@media (max-width: 1024px)': {
        divcertificate: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0px'
        },
    },
    Imgcertificate: {
        height: '17rem',
        '@media (max-width:959px)': {
            height: '9.9rem'
        }
    },
    certificatebox: {
        paddingTop: 15,
        border: "1px solid #E7E7E7",
        display: 'flex',
        gap: '20px',
        alignItems: 'center'

    },
    certificatediv: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center'
    },
    certificateImg: {
        height: '3rem',
    },
    CLPara: {
        fontWeight: '400',
        fontSize: '13px',
        '@media (max-width: 600px)': {
            fontSize: '11px',
            display: 'flex',
            paddingLeft: '18px',
            paddingRight: "18px",
            margin: '0px',
            marginBottom: '2rem',
            textAlign: 'center',
        },
        '@media (min-width: 601px) and (max-width:960px)': {
            marginBottom: '2rem',
            margin: '0px'
        }
    },
    CLtext: {
        color: '#0C3450',
        fontWeight: '700',
        textAlign: 'center',
        '@media (max-width: 600px)': {
            margin: '0px',
            marginTop: '2rem',
        },
        '@media (min-width: 601px) and (max-width:960px)': {
            margin: '0px',
            marginTop: '2rem',
        }
    },

    lockImg: {
        height: '1.3rem',
        border: '#84818A'
    },
    genustext: {
        color: '#84818A'
    },


    ul: {
        listStyleType: "none",
        padding: '0px'
    },

    downloadBtn: {
        width: '140px',
        height: '28px',
        flexShrink: 0,
        borderRadius: '5px',
        border: '1px solid #0C3450',
        color: '#0C3450',
        boxShadow: '2px 4px 8px 0px rgba(157, 172, 254, 0.20)',
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
    },
    pdfTitle: {
        textTransform: 'capitalize',
        marginTop: 0,
        marginLeft: 14,
    },
    videoTitle: {
        textTransform: 'capitalize',
        marginTop: 0
    },

    subjectDiv: {
        border: '1px solid #E7E7E7',
        backgroundColor: '#fff',
        minHeight: '200px',
        padding: '20px',
        margin: '15px',
        maxWidth: '320px',
        cursor: 'pointer'
    },
    pdfDiv: {
        border: '1px solid #E7E7E7',
        backgroundColor: '#fff',
        minHeight: '154px',
        padding: '15px',
        margin: '15px',
        maxWidth: '320px',
    },

    leftContainer: {
        display: 'flex',
    },

    rightContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 24,
    },

    pdfBtn: {
        width: '140px',
        height: '28px',
        flexShrink: 0,
        borderRadius: '5px',
        background: '#0C3450',
        color: '#fff',
        boxShadow: '2px 4px 8px 0px rgba(157, 172, 254, 0.20)',
        display: 'flex',
        alignItems: 'center',
        "&:hover": {
            backgroundColor: '#0C3450',
            color: '#fff',
            cursor: 'pointer',
        },
    },
    icon: {
        fontSize: '35px'
    },
    detailBtn: {
        border: '1px solid grey',
        backgroundColor: '#fff',
        color: '#000',
        borderRadius: '50px',
        padding: '1px 12px',
        fontSize: '14px',
        margin: '10px 5px',
        textTransform: 'capitalize',
        "&:hover": {
            background: "#fff",
        },
    },
    contentBtn: {
        border: '1px solid grey',
        backgroundColor: '#5F4BAB',
        color: '#fff',
        borderRadius: '50px',
        padding: '1px 12px',
        fontSize: '14px',
        margin: '10px 5px',
        textTransform: 'capitalize',
        "&:hover": {
            background: "#5F4BAB",
        },
    },
    backBtn: {
        padding: '1px 5px',
        fontSize: '15px',
        margin: '10px 2px',
        textTransform: 'capitalize',
        "&:hover": {
            background: "#fff",
        },
    },
    link: {
        color: '#000',
        textDecoration: 'none'
    },
    disableBtn: {
        color: 'black',
        padding: '10px',
    },
    enableBtn: {
        background: 'white',
        color: 'black',
        padding: '10px',
        "&:hover": {
            background: "#E6E6E6",
            color: 'black'
        },
    },
    btnBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '5px',
        position: 'sticky',
        bottom: '5%'
    },
    btnBoxInside: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        background: 'white',
        boxShadow: '0px 0px 20px 0 rgb(16 36 94 / 30%)',
        borderRadius: '8px'
    },
    crossIcon: {
        color: 'color',
        background: 'white',
        fontWeight: 'bold',
        margin: '5px 0px 10px 0px',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        padding: '5px',
        "&:hover": {
            background: "#5F4BAB",
            color: 'white'
        },
    },
    crossBox: {
        width: '100%',
        justifyContent: 'flex-end',
        display: 'flex'
    },
    page: {
        fontSize: '15px',
        margin: '0px 10px'
    },
    Loader: {
        background: 'white',
        padding: '10px 100px',
        borderRadius: '10px'
    },

    '@media (min-width: 1024px )': {
        Imgcertificate: {
            height: '40vh'
        },
        mainDiv: {
            display: 'flex',

            width: '90%',

            alignItems: 'center',

        },
        CLtext: {
            fontSize: '25px',
            fontWeight: '700',

            textAlign: 'center'
        },
        CLPara: {
            fontSize: '10px',
            fontWeight: '400',
            margin: '-12px',
            textAlign: 'center'

        }
    },
}

export default TabsLandingPage;
