import React from 'react';
import QuizCard from "../QuizCard/quizCard";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";

/**
 * Quiz list section for web.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizListSection(props) {
    const { quizSectionList, showVertical, handleRefresh, onQuizCardClick, startQuiz, course_id, showInstructions, showInstructionScreen, progressPercent  } = props;
    const t = useTranslations();

    return (
        <div>
                    <div className={"flexBox-container " + (showVertical ? 'saved-quiz-container' : '')}>
                        {
                            quizSectionList.map((data, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <QuizCard quizData={data} t={t} handleRefresh={handleRefresh} onQuizCardClick={onQuizCardClick} startQuiz={startQuiz} course_id={course_id} showInstructions={showInstructions} showInstructionScreen={showInstructionScreen} progressPercent={progressPercent} />
                                    </React.Fragment>
                                );
                            })
                        }
            </div>
        </div>
    );
}

export default QuizListSection;
