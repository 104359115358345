import React, { useEffect, useState } from 'react';
import LanguageSwitcher from "./LanguageSwitcher";
import UserMenuList from "./UserMenuList";
import { useDispatch, useSelector } from "react-redux";
import UserNotification from './UserNotification';
import { getNotifications } from 'examsbook-react-sdk/actions';
import { Box, Button, List, ListItem } from '@material-ui/core';
import { useStyles, useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { ColorsConstant } from '../../../constants/colorConstant';
import { stopImpersonate } from 'examsbook-react-sdk/actions/stopImpersonate';

/**
 * Notification component
 * @param props
 * @returns {*}
 * @constructor
 */
function NotificationBar(props) {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const { quizPage } = props;
    const [ length, setLength ] = useState(50);
    const [notificationList, setNotificationList] = useState([]);
    const t = useTranslations();
    const impersonating = localStorage.getItem('impersonating');
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (user) {
            dispatch(getNotifications(length))
            .then(response => {
                setNotificationList(response);
            })
            .catch(error => {
                console.error('Failed to fetch notifications:', error);
            });
        }
    }, []);
    const stopImpersonation = () => {
        let data = { token };
        dispatch(stopImpersonate(data)).then(response => {
            if(response?.payload?.success === "success"){
                localStorage.clear();
                const redirectUrl = `${response?.payload?.impersonatorUrl}/login?token=${response?.payload?.token}`;
                window.location.href = redirectUrl;
            }}).catch(error => {
                console.error('Failed to stop impersonate:', error);
        });
        
    }

    const styles = {
        stopImpersonateButton: {
              borderRadius:'4px',
              fontSize:'16px',
              marginRight:'10px',
              backgroundColor: ColorsConstant.defaultBlue,
              color: ColorsConstant.white,
              cursor:'pointer',
              textTransform: 'capitalize',
              '&:hover': {
                  backgroundColor: ColorsConstant.defaultBlue,
              },
        },
    }
    const classes = useStyles(styles)();

    return (

        <List className="right-menus" id='rightMenusId' >
            {impersonating && <ListItem><Button onClick={stopImpersonation} className={classes.stopImpersonateButton}>{t('Stop Impersonate')}</Button></ListItem>}
            {!quizPage && <ListItem id="userNotificationId">
                <UserNotification userNotification={notificationList} setLength={setLength}/>
            </ListItem> }
            {!quizPage && <ListItem id="languageSwitchId">
                <LanguageSwitcher user={user}/>
            </ListItem> }
            <ListItem className="login-field" id="loginFieldId">
                <Box className='user-detail-box'>
                <UserMenuList user={user} profile={true} openFeedbackModel={props.openFeedbackModel}/>
                <UserMenuList user={user} profile={false} openFeedbackModel={props.openFeedbackModel}/>
                </Box>
            </ListItem>
        </List>

    );
}

export default NotificationBar;
