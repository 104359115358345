import axios from "./axiosConfig";

export function stopImpersonation(data) {
    return axios
        .post("/api/v1/auth/stop-impersonate", data)
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error;
        });
}