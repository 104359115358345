import React from "react";
import { Box } from "@material-ui/core";
import {
  useSettings,
  useStyles,
  useTranslations,
  useUserLanguage,
} from "examsbook-react-sdk/custom-hooks";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ColorsConstant } from "../constants/colorConstant";

export const LanguageSwitcher = (props) => {
  const t = useTranslations();
  const { language, setUserLanguage } = useUserLanguage();
  const { user } = props;
  const { settingsData } = useSettings(user);
  const supportedLanguages = settingsData?.data?.[0]?.branding?.language?.supportedLanguages || [];
  const languageMap = {
      English: "en",
      Hindi: "hn",
      Assamese: "as",
      Bengali: "bn",
      Gujarati: "gu",
      Kannada: "kn",
      Malayalam: "ml",
      Marathi: "mr",
      Odia: "ory",
      Punjabi: "pa",
      Tamil: "ta",
      Telugu: "te",
      Urdu: "ur"
  };
  const styles = {
    languageChange: {
      padding: "30px 20px",
      "& h6": {
        fontSize: 20,
        margin: "0 0 30px",
      },
      "& ul": {
        listStyle: "none",
        margin: 0,
        padding: 0,
        "& li": {
          border: `1px solid ${ColorsConstant.cardBorders}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
          marginBottom: 15,
          borderRadius: 5,
          minHeight: 66,
          "& span": {
            fontSize: 16,
          },
          "& svg": {
            color: ColorsConstant.defaultBlue,
            display: "none",
          },
          "&.active": {
            border: `1px solid ${ColorsConstant.defaultBlue}`,
            "& svg": {
              display: "flex",
            },
          },
        },
      },
    },
  };
  const classes = useStyles(styles)();
  return (
    <Box className={classes.languageChange}>
      <h6>Language</h6>
      <ul>
      {supportedLanguages.map((lang) => (
        <li key={lang}
        className={language === languageMap[lang] ? "active" : ""}
        onClick={() => setUserLanguage(languageMap[lang])}
        >
          <span>{t(lang)}</span>
          <CheckCircleIcon />
        </li>
      ))}
      </ul>
    </Box>
  );
};
