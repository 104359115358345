import React, { useEffect, useState } from 'react';
import { makeStyles, Box, LinearProgress, Typography } from '@material-ui/core';
import '../CoursesList/coursesList.scss';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addCourse } from 'examsbook-react-sdk/actions/addCourse';
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import PropTypes from 'prop-types';
import { ReactComponent as Live } from "../../../src/assets/images/live.svg";
import { recentCourseAction } from 'examsbook-react-sdk/actions';
import { courseAddedNotification } from 'examsbook-react-sdk/actions/getNotifications';
import OverlayButton from '../../constants/OverlayButton';
import CommonButton from '../../constants/CommonButton';
import { useTheme } from '../../constants/themeProvider';
import moment from "moment";
import { ReactComponent as ExpiringClock } from "../../../src/assets/images/expiringclock.svg";
import { ReactComponent as CertifiedIcon } from "../../../src/assets/images/certifiedIcon.svg";
import DefaultCourseImage from "../../../src/assets/images/course-image.jpg"

/**
 * Test series card
 * @param props
 * @returns {*}
 * @constructor
 */
function CoursesCard(props) {
    const { testData, certificateListing, dueDateinDays, settingsData } = props;
    const history = useHistory();
    const t = useTranslations();
    const classes = useStyles();
    const [isModalVisible, setModalVisible] = useState(false);
    const theme = useTheme();
    const [courseExpiryDate, setCourseEndDate] = useState();
    const [showComponent, setShowComponent] = useState(false);
    const [certificateData, setCertificateData] = useState([])
    const [showExpirationDate, setShowExpirationDate] = useState(false);
    const user = useSelector((state) => state.user);
    let userId = user?._id;
    const [courseAdded, setCourseAdded] = useState(false)
    const dispatch = useDispatch();
    const currentDate = new Date();
    const courseEndDate = new Date(currentDate);
    const callRecentApi = async () => {
        const payload = { courseId: testData._id };
        await dispatch(recentCourseAction(payload));
    };
    const openModal = () => {
        setModalVisible(true);
    };
    
    const closeModal = () => {
    setModalVisible(false);
    };


    function handleViewClick() {
        localStorage.setItem('dataKey', testData._id);
        window.dispatchEvent(new Event('storage'));
        reactGaEvent("Course Material", "View Course");
        callRecentApi();
        localStorage.setItem('courseName',testData?.name);
        localStorage.setItem('courseDescription',testData?.description);
        localStorage.setItem('courseId',testData._id);
        history.push('/course-material/content', {courseId:testData._id, subjectId: '', topicId:'', subjectDetail:testData, subjectName:'', topicName:''});
    }

    const QuizSucessful = ({ showModal, closeModal }) => {
        return (
          <Box className={classes.modalBg + ` ${showModal ? 'show' : ''}`} onClick={closeModal} >
            <Box className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                <Box className={classes.modalHeading}>
                    <h2 className={classes.modalText}>{ t("Course_added_successfully")}</h2>
                    <CommonButton onClick={closeModal} title={t('Ok')} classStyleHeading={classes.okButton}/>
                </Box>
            </Box>
          </Box>
        );
    };

    const updateExpiration = (updatedTestData) => {
        const currentDate = new Date();
    
        const courseEndDate = updatedTestData?.assignedCourseEndDate
            ? new Date(updatedTestData.assignedCourseEndDate)
            : null;
    
        if (courseEndDate) {
            setCourseEndDate(courseEndDate);
            const timeDifference = courseEndDate.getTime() - currentDate.getTime();
            setExpireDays(Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));
        } else {
            setExpireDays(null);
        }
    };
    

    function handleGoToTestClick() {
        const currentTime = Date.now();
        const tempDate = new Date(currentTime);
        tempDate.setDate(tempDate.getDate() + (dueDateinDays > 0 ? dueDateinDays - 1 : 0));
        tempDate.setHours(23, 59, 59, 999);
        const endDate = tempDate.getTime();

        dispatch(
            addCourse({
                userId: user._id,
                courses: [{
                    courseId: testData?._id,
                    startDate: currentTime,
                    dueDateinDays: user?.userExpireInDay <= dueDateinDays ? parseInt(user?.userExpireInDay) : dueDateinDays,
                    endDate
                }],
            })
        ).then(() => {
            const updatedTestData = { ...testData, assignedCourseEndDate: new Date(endDate) };
            updateExpiration(updatedTestData);
        });
    
        setCourseAdded(true);
        let data = {
            tag: "courseAddition",
            courseId: [testData?._id],
            scheduledDate: testData?.endDate,
        }
        dispatch(courseAddedNotification(userId, data));
        setShowExpirationDate(true);

    openModal();
    }
   
    const [expireDays, setExpireDays] = useState(false);
    const [courseExpired, setCourseExpired] = useState(false);
    const percentageCompletion = testData?.progressPercent !== undefined ? testData?.progressPercent : 0

    useEffect(() => {
        checkExpiration();
    }, []);

    useEffect(() => {
        getCertificateListing();
    },[testData, certificateListing])

    useEffect(() => {
        if (testData) {
            updateExpiration(testData);
        }
    }, [testData]);

    const checkExpiration = () => {
        const currentDate = new Date();
    
        // Determine the course end date
        let courseEndDate = testData?.endDate 
            ? new Date(testData.endDate) 
            : testData?.assignedCourseEndDate 
                ? new Date(testData.assignedCourseEndDate) 
                : null;

        // Set course expiry date if available
        if (courseEndDate) {
            setCourseEndDate(courseEndDate);
            const timeDifference = courseEndDate.getTime() - currentDate.getTime();
            setExpireDays(Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));
        } else {
            // If no end date is available, set expiration days to null
            setExpireDays(null);
        }
    };

    
    useEffect(() => {
        if (expireDays === null) {
            // If no expiration days are calculated, do not disable the button
            setCourseExpired(false);
        } else if (expireDays > 0) {
            // If there are days remaining, do not disable the button
            setCourseExpired(false);
        } else {
            // If the course is expired, disable the button
            setCourseExpired(true);
        }
    }, [expireDays]);

    useEffect(() => {
        const timer = setTimeout(() => {
          setShowComponent(true);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    const getCertificateListing = () => {
        const isCourseIdPresent = certificateListing?.filter(item => item?.courseId?._id === testData?._id);
        if (isCourseIdPresent) {
            setCertificateData(certificateListing?.find(item => item?.courseId?._id === testData?._id));
        }
    }

    useEffect(() => {
        if(testData?.isUserCourse) {
            setShowExpirationDate(true);
        }
    },[testData?.isUserCourse])

    return (
        <React.Fragment>
        <Box className="card-list-image">
            <Box className='image-container image-fix'>
                {/* <img style={{objectFit: 'contain', borderRadius: '4px 4px 0px 0px'}}
                    src={testData?.featured ? API_URL + testData?.formattedData?.thumbnail?.jpg : API_URL + isDynamicLogo && isDynamicLogo !== 'undefined' && dynamicLogos} alt="" onError={(e) => {
                        e.target.onerror = null;
                    }} /> */}
                <img style={{objectFit: 'contain', borderRadius: '4px 4px 0px 0px'}}
                    src={
                        testData?.featured || settingsData?.data?.[0]?.branding?.courseImage?.imagePath
                            ? testData?.featured || settingsData?.data?.[0]?.branding?.courseImage?.imagePath
                            : DefaultCourseImage
                    }
                    alt="" onError={(e) => {
                        e.target.onerror = null;
                    }} />

                {testData.isUserCourse && <Box className={classes.courseBottomRight} style={{background: theme?.primaryColor}}>
                    <Box className="my-course-button">
                        <span className='ribbon-text' style={{color: theme?.primaryFontText}}>{t('MY_COURSE')}</span>
                    </Box>
                </Box>}
            </Box>

            <Box className='card-list'>
            <Box className="course-module">
                {testData.isSelfLearning === true ? <button className="course-learning" style={{border: `1px solid ${theme?.primaryColor}`, color: theme?.primaryColor}}>{t('SELF LEARNING')}</button> : ''}
                {testData.isLive === true ? <button className="course-live-learning" style={{border: `1px solid ${theme?.primaryColor}`, color: theme?.primaryColor}}><span className='live-image'><Live/></span><span>{t('live')}</span></button> : ''}
            </Box>
            <p className='card-title'>{testData.name}</p>
            <p className='card-description' dangerouslySetInnerHTML={{ __html: testData?.description }}></p>
            <Box className="course-bottom-right-expire">
            {showExpirationDate && <Box className="my-course-button-expire">
                    {showComponent && <Box style={{display: 'flex', marginTop: '10px'}}><ExpiringClock/></Box>}
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                        {showComponent && <span className={`ribbon-text-expire`} style={{maxWidth: certificateData?.courseId?._id !== testData?._id ? '30vw' : '15vw', marginTop: '10px'}}>{expireDays > 0 ? `${t('Expiring on')} ${moment(courseExpiryDate).format("DD-MM-YYYY")}` : t('Expired')}</span>}
                    </Box>
                </Box>}
                <Box style={{display: 'flex', alignItems: 'center', minHeight: '34px'}}>
                    {certificateData?.courseId?._id === testData?._id && 
                    <Box style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                        {showComponent && <><Box style={{display: 'flex'}}><CertifiedIcon/></Box>
                        <div style={{display: 'flex', alignItems: 'center'}}><span className='certified-text'>{t('Qualified')}</span></div></>}
                    </Box>}
                </Box>
            </Box>

            <Box className='card-title-progress' style={{ minHeight: '26px' }}>
                {!testData.isAddedCourse && <LinearProgressWithLabel value={percentageCompletion} />}
            </Box>
            
            {testData.pricing === "FREE" && (
                testData.isUserCourse ? (
                    <Box className='free-div'>
                        <Box className="card-btn-new">
                            <OverlayButton onClick={handleViewClick} disabled={courseExpired} title={t('VIEW_COURSE')}/>
                        </Box>
                    </Box>
                ) : courseAdded ? (
                    <Box className='free-div'>
                        <Box className="card-btn-new">
                            <OverlayButton onClick={handleViewClick} disabled={courseExpired} title={t('VIEW_COURSE')}/>
                        </Box>
                    </Box>
                ) : (
                    <Box className='free-div'>
                        <Box className="card-btn-new">
                            <OverlayButton onClick={handleGoToTestClick} title={t('ADD_COURSE')}/>
                        </Box>
                    </Box>
                )
            )}

            </Box>
        </Box>
        {isModalVisible && <QuizSucessful showModal={isModalVisible} closeModal={closeModal} />}
        </React.Fragment>
    );
}

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    okButton: {
        borderRadius:'4px',
        padding:'6px 30px',
        fontSize:'15px',
        margin:'15px',
        cursor:'pointer',
        '&:hover': {
            backgroundColor: '#0c3450e0 !important',
        },
    },
    modalBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100% !important',
        background: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '999999'
    },   
    modalContent: {
        width: '420px',
        margin: 'auto',
        marginTop: '40vh',
        '@media (max-width:439px)': {
            width: '280px'
        }
    },
    modalHeading: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'white',
        padding:'20px 40px',
        borderRadius: '6px'
    },
    modalText: {
        fontWeight:'normal',
        textAlign: 'center'
    },
    courseBottomRight: {
        position: 'absolute',
        top: '17px',
        right: '-39px',
        textAlign: 'center',
        transform: 'rotate(45deg)',
        width: '139px',
        zIndex: '0'
      },
}));
export default CoursesCard;
