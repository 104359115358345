import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSettings } from "examsbook-react-sdk/custom-hooks";
import { ColorsConstant } from "./colorConstant";

const ThemeContext = createContext();
function ThemeProvider({ children }) {
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');

  const [primaryFontText, setPrimaryFontText] = useState('');
  const [secondaryFontText, setSecondaryFontText] = useState('');

  const [primaryBg, setPrimaryBg] = useState();
  const [secondaryBg, setSecondaryBg] = useState();
  const [primaryFontColor, setPrimaryFontColor] = useState();
  const [secondaryFontColor, setSecondaryFontColor] = useState();
  const user = useSelector(state => state.user);
  const { settingsData } = useSettings(user);

  const getSettingApiData = async () => {
    try {
      setPrimaryBg(settingsData?.data?.[0] ? settingsData?.data?.[0]?.branding?.backgroundAndTextTheme?.primaryBackgroundColor : ColorsConstant.defaultBlue)
      setSecondaryBg(settingsData?.data?.[0] ? settingsData?.data?.[0]?.branding?.backgroundAndTextTheme?.secondaryBackgroundColor : ColorsConstant.defaultBlue)
      setPrimaryFontColor(settingsData?.data?.[0] ? settingsData?.data?.[0]?.branding?.backgroundAndTextTheme?.primaryTextColor : ColorsConstant.white)
      setSecondaryFontColor(settingsData?.data?.[0] ? settingsData?.data?.[0]?.branding?.backgroundAndTextTheme?.secondaryTextColor : ColorsConstant.white)
    } catch (error) {
      console.log('Error - ', error.message);
    }
  };

  useEffect(() => {
    getSettingApiData();
  }, []);

  useEffect(() => {
    if(primaryBg && secondaryBg && primaryFontColor && secondaryFontColor){  
      const dynamicBgColorPrimary =  primaryBg;
      const dynamicBgColorSecondary = secondaryBg;
     
      const dynamicFontColorPrimary = primaryFontColor;
      const dynamicFontColorSecondary = secondaryFontColor;
  
      setPrimaryColor(dynamicBgColorPrimary ? dynamicBgColorPrimary : ColorsConstant.defaultBlue);
      setSecondaryColor(dynamicBgColorSecondary ? dynamicBgColorSecondary : ColorsConstant.defaultBlue);
  
      setPrimaryFontText(dynamicFontColorPrimary ? dynamicFontColorPrimary : ColorsConstant.white);
      setSecondaryFontText(dynamicFontColorSecondary ? dynamicFontColorSecondary : ColorsConstant.white);
    }
    let theme = {
      primaryBg: primaryBg, 
      secondaryBg: secondaryBg, 
      primaryFontColor: primaryFontColor,
      secondaryFontColor: secondaryFontColor
    }
    localStorage.setItem("theme", JSON.stringify(theme))
  }, [primaryBg, secondaryBg, primaryFontColor, secondaryFontColor])

    // Don't render the children if the theme data is not available
  if (!primaryColor || !secondaryColor || !primaryFontText || !secondaryFontText) {
    return null;
  }

  return (
    <ThemeContext.Provider value={{ primaryColor, secondaryColor, primaryFontText, secondaryFontText }}>
      {children}
    </ThemeContext.Provider>
  );
}
export const useTheme = () => useContext(ThemeContext);

export default ThemeProvider;
