import React, { useState } from "react";
import MathJax from "react-mathjax4";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useSelector, useDispatch } from "react-redux";
import useUserLanguage from "examsbook-react-sdk/custom-hooks/useUserLanguage";
import { toggleMarkForReview } from "examsbook-react-sdk/actions";
import { editor } from "examsbook-react-sdk/common";
import { Button, MenuItem, Select, Box } from "@material-ui/core";
import LongMenu from "../../longMenu";
import pauseImage from "../../../assets/images/pauseImage.png";
import ReviewCheck from "../../../assets/images/ReviewCheck.png";
import ReviewUncheck from "../../../assets/images/ReviewUncheck.png";

/**
 * Question title.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuestionTitle(props) {
  const {
    number,
    title,
    localesTitle,
    direction,
    localesDirection,
    questionMark,
    mark,
    classNameInView,
    viewMode,
    timeSpent,
    penalty,
    isPdf,
    mode,
    handleQuizPauseChange,
    pauseQuiz,
    setPauseQuiz,
    type,
  } = props;
  editor(title);
  const [reviewLater] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const classes = useStyles(styles)();
  const t = useTranslations();
  const dispatch = useDispatch();
  const { language, setUserLanguage } = useUserLanguage();
  const selectedQuestion = useSelector((state) => state.selectedQuestion);
  const markButtonActive = selectedQuestion ? selectedQuestion.marked : false;
  const ModelOpen = false;

  const handleChanged = (event) => {
    setSelectedValue(event.target.value);
  };

  function quizPauseChange(value) {
    setPauseQuiz(!pauseQuiz);
    handleQuizPauseChange(value);
  }

  function toggleMarkForReviewFn() {
    dispatch(toggleMarkForReview());
  }
  return (
    <>
      <Box className={classes.englangOption}>
        <Select
          value={selectedValue}
          onChange={handleChanged}
          displayEmpty
          disableUnderline
          className={classes.langWidth}
        >
          <MenuItem
            value=""
            className={language === "en" ? "selected" : ""}
            onClick={() => {
              setUserLanguage("en");
            }}
          >
            <Box
              component="span"
              className={classes.langText}
              style={{ marginLeft: "5%" }}
            >
              English
            </Box>
          </MenuItem>
          <MenuItem
            value="option2"
            className={language === "hn" ? "selected" : ""}
            onClick={() => {
              setUserLanguage("hn");
            }}
          >
            <Box component="span" style={{ marginLeft: "5%" }}>
              Hindi
            </Box>
          </MenuItem>
        </Select>
        <Box
          className={
            classes.questionHeading +
            " " +
            (direction && direction.type === "LONG_TEXT"
              ? classes.questionDirectionHeading
              : "")
          }
        >
          {!viewMode ? (
            <Box className={classes.reviewAndSaveDetail}>
              <span className={classes.markForReview}>
                {markButtonActive && (
                  <img
                    alt=""
                    src={ReviewCheck}
                    checked={markButtonActive ? markButtonActive : false}
                    onClick={(e) => toggleMarkForReviewFn()}
                    className={classes.reviewTick}
                    value={reviewLater || ""}
                  />
                )}
                {!markButtonActive && (
                  <img
                    alt=""
                    src={ReviewUncheck}
                    checked={markButtonActive ? markButtonActive : false}
                    onClick={(e) => toggleMarkForReviewFn()}
                    className={classes.reviewUntick}
                    value={reviewLater || ""}
                  />
                )}

                <label id="radio">{t("Review")}</label>
              </span>
            </Box>
          ) : (
            ""
          )}
          <Box className={classes.queTagBold}>
            <Box className={classes.marksDistribution}>
              <Box className={classes.marksContainer}>
                {!viewMode ? (
                  <React.Fragment>
                    <Box className={classes.marksCount}>
                      <span className={classes.totalMarksContainer}>
                        {" "}
                        + {questionMark}{" "}
                      </span>
                      <span
                        className={
                          classes.totalMarksContainer + " " + classes.deduct
                        }
                      >
                        {" "}
                        - {penalty}{" "}
                      </span>
                    </Box>
                  </React.Fragment>
                ) : (
                  !isPdf && (
                    <React.Fragment>
                      <Box className={classes.marksCount}>
                        <span
                          className={
                            classes.totalMarksContainer + " " + classNameInView
                          }
                        >
                          {mark}{" "}
                        </span>
                      </Box>
                      <Box className={classes.marksCount}>
                        <span className={classes.marksBadges}>
                          {timeSpent}{" "}
                        </span>
                      </Box>
                    </React.Fragment>
                  )
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.langOption}>
            {mode !== "live-test" && (
              <Box className={classes.pauseAndReport}>
                <Box className={classes.pauseAndFeedbackAlign}>
                  <Button
                    className={classes.pauseButton}
                    onClick={() => quizPauseChange(!ModelOpen)}
                    variant="outlined"
                  >
                    <img src={pauseImage} alt="pauseImage" />
                    <span className={classes.pauseText}>Pause</span>
                  </Button>
                  <LongMenu
                    isIconButtonComponent={false}
                    question={selectedQuestion}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box component="span" className={classes.markForReviewMobile}>
          {markButtonActive && (
            <Box
              component="img"
              alt=""
              src={ReviewCheck}
              checked={markButtonActive ? markButtonActive : false}
              onClick={(e) => toggleMarkForReviewFn()}
              className={classes.reviewTick}
              value={reviewLater || ""}
            />
          )}
          {!markButtonActive && (
            <img
              alt=""
              src={ReviewUncheck}
              checked={markButtonActive ? markButtonActive : false}
              onClick={(e) => toggleMarkForReviewFn()}
              className={classes.reviewUntick}
              value={reviewLater || ""}
            />
          )}

          <label id="radio">{t("Review")}</label>
        </Box>

        {mode !== "live-test" && (
          <Box className={classes.pauseAndReport}>
            <Box className={classes.pauseAndFeedbackAlignMobile}>
              <Button
                className={classes.pauseButton}
                onClick={() => quizPauseChange(!ModelOpen)}
                variant="outlined"
              >
                <img src={pauseImage} alt="pauseImage" />
                <span className={classes.pauseText}>Pause</span>
              </Button>
              <LongMenu
                isIconButtonComponent={false}
                question={selectedQuestion}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box className={classes.questionText}>
        <Box className={classes.englishQuestionText}>
          {type === "MultiChoice" && (
            <span className={classes.multipleText}>
              *{t("You can select more than one option")}
            </span>
          )}

          <span className={classes.queTagBold}>
            {!localesTitle || localesTitle === title ? "Q." + number : ""}{" "}
          </span>

          {direction && direction.direction ? (
            <Box className={classes.hiddenSm}>
              <h4>
                <MathJax.Context input="tex">
                  <MathJax.Html
                    html={
                      direction.direction
                        ? direction.direction
                        : localesDirection
                    }
                  />
                </MathJax.Context>
              </h4>
            </Box>
          ) : null}
          <span
            className={
              !localesTitle || localesTitle !== title
                ? "mathjax-line"
                : "mathjax-line-space"
            }
          >
            {direction && direction.type === "SHORT_TEXT" ? (
              <h4 className="web-hindi-text">
                <MathJax.Context input="tex">
                  <MathJax.Html
                    html={
                      direction.direction
                        ? direction.direction
                        : localesDirection
                    }
                  />
                </MathJax.Context>
              </h4>
            ) : null}
            <MathJax.Context input="tex">
              <MathJax.Html html={title ? title : localesTitle} />
            </MathJax.Context>
          </span>
        </Box>
      </Box>
    </>
  );
}

const styles = (theme) => ({
  hiddenSm: {
    display: "none",
  },
  pauseText: {
    "@media (min-width: 768px)": {
      paddingLeft: "12px",
    },
  },
  langWidth: {
    width: "190px",
    "@media (min-width: 320px) and (max-width: 1024px)": {
      width: "100px",
      display: "none",
    },
  },
  langText: {
    "@media (min-width:280px) and (max-width:1024px)": {
      fontSize: "15px",
    },
  },
  pauseAndFeedbackAlign: {
    display: "flex",
    "@media (min-width: 280px) and (max-width: 767px)": {
      display: "none",
    },
  },
  pauseAndFeedbackAlignMobile: {
    display: "flex",
  },
  multipleText: {
    color: "#F75151",
    position: "absolute",
    fontSize: "12px",
    fontStyle: "italic",
    marginTop: "-15px",
    marginBottom: "45px",
    fontWeight: "300",
    lineHeight: "15px",
    minHeight: "15px",
  },
  questionText: {
    "-webkit-user-select": "none",
    "-webkit-touch-callout": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
    float: "left",
    textAlign: "left",
    width: "calc(100% - 20px)",
    padding: 10,
    position: "relative",
    lineHeight: "22px",
    fontWeight: 400,
    color: "#5B5B5B",
    "& .MathJax_Display": {
      display: "contents !important",
    },
    "& .web-hindi-text": {
      fontWeight: "bold",
    },
    "@media (min-width: 280px) and (max-width: 767px)": {
      padding: "10px 0",
      width: "90%",
      overflowWrap: "break-word",
      fontSize: "15px",
      paddingLeft: "20px",
    },
  },
  queTagBold: {
    float: "left",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    fontWeight: "500",
    marginRight: 10,
    color: "#0C3450",
    "@media (min-width:280px) and (max-width:767px)": {
      fontSize: "18px",
    },
  },
  englishQuestionText: {
    width: "100%",
    padding: 0,
    float: "left",
    paddingLeft: "40px",
    marginTop: "60px",
    fontSize: "20px",
    display: "flex",
    alignItems: "baseline",
    "@media (min-width:280px) and (max-width:767px)": {
      marginTop: "44px",
      alignItems: "baseline",
      fontSize: "18px",
      padding: "0px",
    },
    "& span": {
      "& div": {
        "& p": {
          "& img": {
            height: "200px",
            width: "300px",
          },
        },
      },
    },
  },
  hindiQuestionText: {
    width: "100%",
    padding: 0,
    float: "left",
    paddingLeft: "40px",
    marginTop: "80px",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    "@media (min-width:280px) and (max-width:767px)": {
      marginTop: "44px",
      alignItems: "baseline",
      fontSize: "18px",
      padding: "0px",
    },
  },
  totalMarksSection: {
    color: "#000",
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: 30,
    top: 6,
    marginLeft: 15,
    marginRight: 15,
    borderWidth: 1,
    borderBottomColor: "#eee",
    "@media (min-width:320px) and (max-width:1024px)": {
      marginLeft: 0,
      marginRight: 0,
      position: "static",
      marginBottom: 10,
      width: "100%",
      marginTop: 20,
    },
  },
  totalMarks: {
    marginBottom: 12,
    marginRight: 20,
    "@media (min-width:320px) and (max-width:1024px)": {
      marginRight: 14,
      marginBottom: 0,
    },
    "@media (min-width:320px) and (max-width:767px)": {
      marginRight: 6,
      marginBottom: 0,
    },
  },
  totalMarksText: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    marginRight: 10,
    "@media (min-width:320px) and (max-width:767px)": {
      fontSize: 12,
      marginRight: 5,
    },
  },
  totalMarksContainer: {
    padding: "2px 6px",
    color: "#FAFAFA",
    backgroundColor: "#3FA159",
    fontWeight: "bold",
    borderRadius: 2,
    fontSize: 13,
    display: "flex",
    border: "1px solid #5F4BAB",
    "@media (min-width:320px) and (max-width:1024px)": {
      paddingLeft: 5,
      paddingRight: 5,
    },
    "@media (min-width:320px) and (max-width:767px)": {
      fontSize: 11,
    },
  },
  deduct: {
    backgroundColor: "#AC3C2B",
    fontWeight: "bold",
    marginLeft: 15,
    color: "#FAFAFA",
    border: "1px solid #c0392b",
  },
  questionHeading: {
    position: "absolute",
    top: 20,
    width: "100%",
    paddingLeft: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (min-width:280px) and (max-width:767px)": {
      top: 10,
      paddingLeft: "0px",
      height: 40,
      display: "inherit",
      zIndex: 9,
      position: "absolute",
      alignItems: "center",
      paddingTop: 0,
      justifyContent: "right",
    },
    "@media (min-width:768px) and (max-width:1100px)": {
      marginTop: 10,
    },
  },
  questionDirectionHeading: {
    position: "static",
    "@media (max-width:767px)": {
      position: "fixed",
    },
  },
  marksCount: {
    marginRight: 10,
    display: "flex",
  },
  reviewTick: {
    marginRight: "8% !important",
  },
  reviewUntick: {
    marginRight: "10% !important",
  },
  marksBadges: {
    backgroundColor: "#ffc65820",
    color: "#ffc658",
    border: "1px solid #ffc658",
    borderRadius: 2,
    padding: "2px 6px",
    fontSize: 13,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginRight: 0,
    display: "flex",
  },
  marksContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
  },
  showAnswerIcon: {
    position: "absolute",
    top: 0,
    left: 90,
    color: "##0C3450",
    cursor: "pointer",
    fontSize: 24,
    "@media (min-width:280px) and (max-width:320px)": {
      top: -1,
      left: 260,
      fontSize: 24,
      marginLeft: 0,
      position: "absolute",
    },
    "@media (min-width:321px) and (max-width:425px)": {
      top: 0,
      left: 36,
      fontSize: 24,
      marginLeft: "80%",
      position: "absolute",
    },
    "@media (min-width:426px) and (max-width:767px)": {
      top: 0,
      left: 45,
      fontSize: 24,
      marginLeft: "40%",
      position: "absolute",
    },
    "@media (min-width:768px) and (max-width:2560px)": {
      top: 0,
      left: -85,
      fontSize: 24,
      marginLeft: "40%",
      position: "absolute",
    },
    "& .fa-bookmark-o": {
      color: "##0C3450",
    },
    "& .fa-bookmark": {
      color: "##0C3450",
    },
  },
  markBtn: {
    float: "none",
    width: "auto",
    textAlign: "left",
    backgroundColor: "#fff",
    display: "inline-block",
    "& span": {
      color: "#888",
    },
    "@media (max-width: 767px)": {
      backgroundColor: "transparent",
      right: 14,
      position: "relative",
    },
  },
  active: {
    "& span": {
      color: "#0b2f8a",
    },
  },
  markForReview: {
    display: "flex",
    padding: 0,
    paddingRight: 46,
    borderRadius: 5,
    textAlign: "center",
    fontSize: 14,
    cursor: "pointer",
    "& input": {
      width: 20,
      height: 20,
      margin: 0,
      marginRight: 5,
      top: 1,
      cursor: "pointer",
    },
    "@media (min-width:280px) and (max-width:767px)": {
      paddingLeft: 0,
      padding: 0,
      paddingRight: 0,
      fontSize: 0,
      display: "none",
    },
  },
  markForReviewMobile: {
    display: "none",
    padding: 0,
    paddingRight: 46,
    borderRadius: 5,
    textAlign: "center",
    fontSize: 14,
    cursor: "pointer",
    marginLeft: 8,
    "& input": {
      width: 20,
      height: 20,
      margin: 0,
      marginRight: 5,
      top: 1,
      cursor: "pointer",
    },
    "@media (min-width:280px) and (max-width:767px)": {
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  pauseAndReport: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
  },
  pauseButton: {
    color: "#0C3450",
    fontSize: "18px",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "113.5%",
    border: "1px solid #E7E7E7",
    textTransform: "capitalize",
    background: "#FFF",
    boxShadow: "4px 4px 8px 0px rgba(198, 198, 198, 0.25)",
    margin: "9px",
    position: "relative",
    right: "15%",
    display: "none",
  },
  langOption: {
    display: "none",
    justifyContent: "end",
    marginLeft: "5%",
    "@media (min-width:280px) and (max-width:320px)": {
      paddingLeft: 0,
      padding: 0,
      paddingRight: 0,
      fontSize: 13,
      display: "flex",
    },
    "@media (min-width:321px) and (max-width:767px)": {
      paddingLeft: 0,
      padding: 0,
      paddingRight: 0,
      fontSize: 13,
      display: "block",
    },
  },
  englangOption: {
    "@media (min-width:280px) and (max-width:767px)": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 15,
      paddingLeft: 0,
    },
    display: "none",
    color: "#0C3450",
    textAlignLast: "left",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "113.5%",
    "& div": {
      "&.MuiSelect-selectMenu": {
        border: "1px solid #E7E7E7",

        background: "#FFF",
        color: "#0C3450",
        textAlign: "center",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "113.5%",
      },
    },
  },
  reviewAndSaveDetail: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  saveDetail: {
    fontSize: "14px",
    "@media (min-width:280px) and (max-width:767px)": {
      paddingLeft: 0,
      padding: 0,
      paddingRight: 0,
      fontSize: 0,
      display: "none",
    },
  },
  saveDetailMobile: {
    fontSize: "14px",
    "@media (min-width:280px) and (max-width:320px)": {
      paddingLeft: 35,
      padding: 0,
      paddingRight: 0,
      fontSize: 0,
      marginBottom: 65,
    },
    "@media (min-width:321px) and (max-width:767px)": {
      paddingLeft: 25,
      padding: 0,
      paddingRight: 0,
      fontSize: 0,
      marginBottom: 65,
    },
  },
});
export default QuestionTitle;
