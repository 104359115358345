import React from 'react';
import { useSettings, useTranslations, useUserLanguage } from "examsbook-react-sdk/custom-hooks";
import translatorLogo from "../../../assets/images/newTranslatorLogo.png"
import { Box, List, ListItem, Typography } from '@material-ui/core';

/**
 * Language switcher.
 * @returns {*}
 * @constructor
 */
function LanguageSwitcher(props) {
    const t = useTranslations();
    const { user } = props;
    const { settingsData } = useSettings(user);
    const { language, setUserLanguage } = useUserLanguage();
    const supportedLanguages = settingsData?.data?.[0]?.branding?.language?.supportedLanguages || [];

    const languageMap = {
        English: "en",
        Hindi: "hn",
        Assamese: "as",
        Bengali: "bn",
        Gujarati: "gu",
        Kannada: "kn",
        Malayalam: "ml",
        Marathi: "mr",
        Odia: "ory",
        Punjabi: "pa",
        Tamil: "ta",
        Telugu: "te",
        Urdu: "ur"
    };
    return (
        <Box>
            <Box component="img" id="languageImageId" src={translatorLogo} className="languageImage" alt=""/>
            <List className="sub-menus">
                {supportedLanguages.map((lang) => {
                    const langCode = languageMap[lang];
                    return (
                        <ListItem
                            key={lang}
                            className={language === langCode ? "selected" : ""}
                            onClick={() => {
                                setUserLanguage(langCode);
                            }}
                        >
                            <Typography variant="span">{t(lang)}</Typography>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}

export default LanguageSwitcher;
