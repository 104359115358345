import React from "react";
import { CNavLink } from "./c/CHeader";
import CUserImageIcon from "../../CUserImageIcon";
import {
  useTranslations
} from "examsbook-react-sdk/custom-hooks";
import { Box, List, ListItem, Typography } from "@material-ui/core";
import defaultImage from "../../../assets/images/student.png";

/**
 * UserMenuList
 * @param props
 * @returns {*}
 * @constructor
 */
function UserMenuList(props) {
  const { user, profile } = props;
  const t = useTranslations();

  const logout = async () => {
    await localStorage.clear();
    localStorage.removeItem('showAlert');
    window.location.href = "/logout";
  };

  return (
    <React.Fragment>
      {profile ? (
        <Box id="userSectionId">
          <CUserImageIcon src={user?.image ? `${user.image}`: defaultImage} />
          <List className="sub-menus">
            <ListItem>
              <CNavLink to="/my-profile">{t("my_profile")}</CNavLink>
            </ListItem>
            <ListItem onClick={() => logout()}>{t("logout")}</ListItem>
          </List>
        </Box>
      ) : (
        <Box
          id="contentBlockId"
        >
          <Typography variant="p" className="profile-info" id="contentId">{user.name}</Typography>
          <Typography variant="p" className="profile-info" id="content2Id">{user.city}</Typography>
        </Box>
      )}
    </React.Fragment>
  );
}

export default UserMenuList;
