import React from "react";
import { useStyles } from "examsbook-react-sdk/custom-hooks";
import { useTheme } from "../../constants/themeProvider";
import { Box } from "@material-ui/core";
/**
 * Text block.
 * @param props
 * @returns {*}
 * @constructor
 */
function LearningText(props) {
  const theme = useTheme();
  const classes = useStyles(styles)();

  return (
    <Box>
      <div className={classes.textTitle}>{props?.textName}</div>
      <div
        className={`${classes.textData} ql-editor`}
        dangerouslySetInnerHTML={{ __html: props?.textData?.detail }}
      />
    </Box>
  );
}

const styles = (theme) => ({
  textTitle: {
    color: "#0C3450",
    fontSize: "24px",
    fontWeight: 500,
  },
  textData: {
    color: "#000000CC",
    "& p": {
      margin: 0,
      "& img": {
        height: "300px",
        width: "auto",
        "@media (max-width: 767px)": {
          height: "200px",
        },
      },
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      margin: 0,
    },
  },
});
export default LearningText;
