import { getSettingsApi } from "../services/settingsService";

export const getSettingsAction = (subdomain) => (dispatch) => {
    dispatch(request());
    return getSettingsApi(subdomain)
        .then((response) => {
            // handle success
            if (response?.data) {
                dispatch(success(response));
                return response;
            } else {
                throw new Error("Invalid settings api response");
            }
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "GET_SETTINGS_REQUEST" };
    }

    function success(response) {
        return { type: "GET_SETTINGS_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "GET_SETTINGS_FAILURE", payload: error };
    }
};

export const setSubdomain = (subdomain) => (dispatch) => {
  localStorage.setItem("subdomain", subdomain);
  dispatch({ type: "SET_SUBDOMAIN", payload: subdomain });
};