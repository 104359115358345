import React, { useEffect, useState } from 'react';
import { useSettings, useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import classNames from 'classnames';
import { getQuizStatus } from "examsbook-react-sdk/common";
import LmsHeaderLogoDark from "../../../assets/images/LearnifyLogoDark.png";
import { useSelector } from 'react-redux';

/**
 * QuizStatus component.
 * @param props
 * @returns {*}
 * @constructor
 */
const QuizStatus = props => {
    const { result, viewMode, questions } = props;
    const classes = useStyles(styles)();
    const t = useTranslations();
    const [ dynamicLogos, setDynamicLogos ] = useState('');
    const user = useSelector((state) => state.user);
      const { settingsData } = useSettings(user);

    const {
        answered,
        marked,
        notVisited,
        notAnswered,
        notAnsweredVisited
    } = getQuizStatus(questions);
    let rightAns = 0, wrongAns = 0;
    if (result && result.length) {
        result.forEach((sectionResult) => {
            rightAns += sectionResult.rightAns;
            wrongAns += sectionResult.wrongAns;
        });
    }

    useEffect(() => {
        const imageValue = settingsData?.data?.[0]?.branding?.logos?.webAndAppLogo?.imagePath;
        let dynamicLogo = "";
        if (imageValue) {
            dynamicLogo = imageValue;
        }
        setDynamicLogos(dynamicLogo ? dynamicLogo : LmsHeaderLogoDark);
    }, [ settingsData ]);

    return (
        <React.Fragment>
            <div className={[ classes.legendSection ]}>
                <img alt="" className={classes.logo} src={dynamicLogos}/>
                <div className={classes.line}></div>
                <React.Fragment>
                    {!viewMode ?
                        <div className={classes.questionReviewBlock}>
                            <div className={classes.questionCategory}>
                                <span
                                    className={classNames(classes.background) + ' ' + classNames(classes.right)}>
                                    <span className={classes.count}>{answered || 0}</span>
                                    </span>
                                <span className={classes.label}>{t('Answered')}</span>
                            </div>
                            <div className={classes.questionCategory}>
                                <span
                                    className={classNames(classes.background) + ' ' + classNames(classes.wrong)}>
                                        <span className={classes.count}>{notAnswered || 0}</span>
                                    </span>
                                <span className={classes.label}>{t('Not Answered')}</span>
                            </div>
                            <div className={classes.questionCategory}>
                                <span
                                    className={classNames(classes.background) + ' ' + classNames(classes.notVisited)}>
                                    <span className={classes.count}>{notVisited || 0}</span>
                                    </span>
                                <span className={classes.label}>{t('Not Visited')}</span>
                            </div>
                            <div className={classes.questionCategory}>
                                <span
                                    className={classNames(classes.background) + ' ' + classNames(classes.marked)}>
                                         <span className={classes.count}>{marked || 0}</span>
                                    </span>
                                <span className={classes.label}>{t('Marked')}</span>
                            </div>
                        </div> : <div className={classes.questionReviewBlock}>
                            <div className={classes.questionCategory}>
                                <span
                                    className={classNames(classes.background) + ' ' + classNames(classes.right)}></span>
                                <span
                                    className={classes.label}>{t('Right')} <span>({rightAns || 0})</span></span>
                            </div>
                            <div className={classes.questionCategory}>
                                <span
                                    className={classNames(classes.background) + ' ' + classNames(classes.wrong)}></span>
                                <span
                                    className={classes.label}>{t('Wrong')} <span>({wrongAns || 0})</span></span>
                            </div>
                            <div className={classes.questionCategory}>
                                <span
                                    className={classNames(classes.background) + ' ' + classNames(classes.notAnswered)}></span>
                                <span
                                    className={classes.label}>{t('Not Answered and Visited')}
                                    <span>({notAnsweredVisited || 0})</span></span>
                            </div>
                            <div className={classes.questionCategory}>
                                <span
                                    className={classNames(classes.background) + ' ' + classNames(classes.notVisited)}></span>
                                <span className={classes.label}>{t('Not Visited')}
                                    <span>({notVisited || 0})</span></span>
                            </div>
                        </div>
                    }
                </React.Fragment>
            </div>
        </React.Fragment>
    );
};

const styles = theme => ({
    legendSection: {
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        '@media (max-width: 800px)': {
            position: 'fixed',
            width: '100%',
            marginTop: 0,
            padding: '10px !important',
            background: '#fff',
            zIndex: 999,
            height: '-webkit-fill-available'
        },
        '@media (min-width: 280px) and (max-width: 800px)': {
            zIndex: '0 !important'
        }
    },
    line: {
        '@media (min-width: 768px)': {
            borderBottom: '1px solid #E3E3E3'
        }
    },
    background: {
        width: 20,
        height: 20,
        display: 'inline-block',
        borderRadius: '50%'
    },
    right: {
        backgroundColor: '#27ae60',
    },
    notAnswered: {
        backgroundColor: '#FFC658'
    },
    wrong: {
        backgroundColor: '#BF0606'
    },
    notVisited: {
        backgroundColor: '#FFA61B',
        color: '#000'
    },
    markedAnswered: {
        backgroundColor: '#0b2f8a',
        position: 'relative',
        '& i': {
            position: 'absolute',
            right: -4,
            top: -4,
            color: '#27ae60',
            fontSize: 16,
            '@media (max-width:767px)': {
                top: -8,
                fontSIze: 22
            }
        }
    },
    marked: {
        backgroundColor: '#0b2f8a'
    },
    width100: {
        width: '100%'
    },
    label: {
        color: '#000',
        fontSize: 16,
        display: 'inline-block',
        textAlign: 'left',
        paddingLeft: 9,
        width: 'calc(100% - 30px)',
        '@media (max-width: 767px)': {
            width: '100%',
            paddingLeft: 0,
            paddingTop: 6,
            position: 'relative',
            '& > span': {
                position: 'absolute',
                top: -19,
                left: 25,
                fontSize: 14
            }
        }
    },
    questionReviewBlock: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginTop: 15,
        marginLeft: 15,
        justifyContent: 'space-between',
        '@media (min-width:280px) and (max-width:767px)': {
            flexDirection: 'row',
            textAlignLast: "center",
            marginTop: 0
        },
        '@media (min-width:768px) and (max-width:800px)': {
            flexDirection: 'row',
            textAlignLast: "center",
            paddingLeft: 34,
            marginTop: 10
        },
    },
    questionCategory: {
        marginBottom: 15,
        display: 'flex',
        alignItems: 'center',
        width: '50%',
        '@media (min-width:280px) and (max-width:767px)': {
            flexDirection: 'row',
        },
    },
    count: {
        color: 'white',
        fontSize: '14px',
    },
    logo: {
        width: '128px',
        alignSelf: 'center',
        marginRight: "14%",
        '@media (max-width:800px)': {
            width: '36%',
            alignSelf: 'center',
            marginRight: 0
        },
        '@media (min-width:768px) and (max-width:800px)': {
            marginTop: 50
        },
    }

});

export default QuizStatus;
