import axios from './axiosConfig';

export function getSettingsApi(subdomain) {
    return axios
    .get(`/settings?subdomain=${subdomain}`)
        .then((response) => {
            // handle success
            return response;
        })
        .catch((error) => {
            // handle error
            return error.data;
        });
}