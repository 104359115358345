import React from "react";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { ReactComponent as LiveIcon } from "../../assets/images/LiveImg.svg";
import moment from "moment";
import { addLiveTracking } from "examsbook-react-sdk/actions";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";

export default function LiveClassCard(props) {
  const liveData = props?.data?.contentData;
  const dispatch = useDispatch();
  const classes = useStyles(styles)();
  const t = useTranslations();
  const title = props?.data?.className;
  const currentDate = new Date().getTime();
  let quizStartDate = liveData?.startDate;
  let quizEndDate = liveData?.endDate;
  const quizStartTime = props?.data?.contentData?.startTime;
  const quizEndTime = props?.data?.contentData?.endTime;
  const currentDateTime = new Date().getTime();
  const quizStartDateTime = quizStartDate
    ? new Date(quizStartDate).setHours(
        quizStartTime?.split(":")[0] || 0,
        quizStartTime?.split(":")[1] || 0
      )
    : null;

  const quizEndDateTime = quizEndDate
    ? new Date(quizEndDate).setHours(
        quizEndTime?.split(":")[0] || 0,
        quizEndTime?.split(":")[1] || 0
      )
    : null;

  const liveClassDisable =
    (currentDateTime >= quizStartDateTime &&
      currentDateTime > quizEndDateTime) ||
    currentDateTime < quizStartDateTime;

  const callLiveTrackingApi = () => {
    let data = {
      classId: props?.data?.classId,
      courseId: props?.sectionData?.data?.courseId?._id,
    };
    dispatch(addLiveTracking(data));
  };

  const handleClick = () => {
    if (!liveClassDisable) {
      window.open(liveData?.url, "_blank");
      callLiveTrackingApi();
    }
  };
  return (
    <>
      <Box
        className={classes.buttonDisabled}
        disabled={liveClassDisable}
        onClick={handleClick}
      >
        <Box
          className={
            liveClassDisable
              ? classes.bgDisable + " " + classes.liveclassbox
              : classes.liveclassbox
          }
        >
          <Box className={classes.certificatediv}>
            <Box className={classes.certificatemaindiv}>
              <LiveIcon className={classes.certificateImg} />
              <Box className={classes.liveText}>
                <p className={classes.genustext}>
                  {title.charAt(0).toUpperCase() + title.slice(1)}
                </p>
                <p className={classes.dateText}>
                  {moment(props?.data?.classId?.startDate).format(
                    "DD MMM YYYY"
                  )}
                </p>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              border:
                currentDateTime >= quizStartDateTime &&
                currentDateTime < quizEndDateTime
                  ? "1px solid green"
                  : currentDateTime > quizEndDateTime
                  ? "1px solid red"
                  : "1px solid grey",
            }}
            className={classes.expiredQuizStatusDiv}
          >
            <p
              style={{
                color:
                  currentDateTime >= quizStartDateTime &&
                  currentDateTime < quizEndDateTime
                    ? "green"
                    : currentDateTime > quizEndDateTime
                    ? "red"
                    : "grey",
              }}
              className={classes.expiredQuizText}
            >
              {currentDateTime >= quizStartDateTime &&
              currentDateTime < quizEndDateTime
                ? t("live")
                : currentDateTime > quizEndDateTime
                ? t("Expired")
                : currentDateTime < quizStartDateTime
                ? t("Upcoming")
                : ""}
            </p>
          </Box>

          <Box className={classes.dashtimeDivBox}>
            <Box className={classes.dashtimeDiv}>
              <Box className={classes.timeDiv}>
                <Box className={classes.timeSpan}>
                  {props?.data?.contentData?.startTime}
                </Box>
                <Box className={classes.timeSpan}>
                  {props?.data?.contentData?.endTime}
                </Box>
              </Box>
              <Box className={classes.dashedLine}>
                <Box style={{ ...styles.redPoint, ...styles.leftPoint }}></Box>
                <Box style={{ ...styles.redPoint, ...styles.rightPoint }}></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

const styles = (theme) => ({
  liveclassbox: {
    border: "1px solid rgba(231, 231, 231, 0.933)",
    display: "flex",
    margin: "15px 0",
    padding: "12px",
    transition: "background-color 0.3s",
    cursor: "pointer",
    borderRadius: "6px",
    background: "#FFFFFF",
    boxShadow: "1.95px 1.95px 2.6px 0px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  certificatemaindiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "11px",
    left: "14px",
    top: "14px",
  },
  certificateImg: {
    width: "40px",
    height: "40px",
    flexShrink: 0,
    borderRadius: "2px",
  },
  buttonDisabled: {
    border: "0",
    width: "-webkit-fill-available",
    backgroundColor: "transparent",
    textAlign: "left",
  },
  bgDisable: {
    backgroundColor: "#ececec",
    border: "1px solid rgba(231, 231, 231, 0.933)",
    display: "flex",
    margin: "15px 0",
    padding: "12px",
    transition: "background-color 0.3s",
    cursor: "not-allowed",
    borderRadius: "6px",
    boxShadow: "1.95px 1.95px 2.6px 0px rgba(0, 0, 0, 0.15)",
  },
  expiredQuizStatusDiv: {
    alignSelf: "center",
    fontWeight: "bold",
    marginLeft: "auto",
    border: "1px solid",
    borderRadius: "15px",
    minWidth: "50px",
  },
  expiredQuizText: {
    fontSize: "12px",
    margin: "0px",
    padding: "4px",
    textAlign: "center",
    fontWeight: "600",
    "@media (max-width: 767px)": {
      fontSize: "10px",
    },
  },
  genustext: {
    fontWeight: "500",
    margin: 0,
    color: "#000000",
    opacity: "0.6",
    fontSize: "16px",
    "@media (max-width: 767px)": {
      fontSize: "10px",
    },
    "@media (min-width: 960px) and (max-width: 1350px)": {
      fontSize: "13px",
    },
  },
  dateText: {
    margin: 0,
    fontSize: "14px",
    color: "#000000",
    opacity: "60%",
    "@media (min-width: 960px) and (max-width: 1350px)": {
      fontSize: "11px",
      marginTop: "7px",
    },
  },
  dashtimeDiv: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    // alignItems:'center'
  },
  dashedLine: {
    marginRight: "8px",
    borderLeft: "1px dashed #1868A0",
    height: "25px",
    position: "relative",
  },
  redPoint: {
    position: "absolute",
    width: "4px",
    height: "4px",
    background: "#1868A0",
    borderRadius: "50%",
  },
  leftPoint: {
    top: "-6px",
    left: "-2.2px",
  },
  rightPoint: {
    bottom: "-6px",
    left: "-2.8px",
  },

  timeDiv: {
    color: "#84818A",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  timeSpan: {
    color: "#000000",
    opacity: "60%",
    fontSize: "12px",
    fontWeight: "300",
  },
  dashtimeDivBox: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "2px",
  },
});
