import React, { useEffect, useState } from "react";
import ScoreCard from "./scores";
import AttemptCard from "./attempts";
import DistributionCards from "./distribution";
import "./reports.scss";
import { courseResult, courseResultByCourseId } from "examsbook-react-sdk/actions/courseResult";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import { QuestionDistribution } from "./time-spent";
import { useSettings, useTranslations } from "examsbook-react-sdk/custom-hooks";

function Reports() {
  const location = useLocation();
  const [courseResultData, setCourseResultData] = useState();
  const [activeAttempts, setActiveAttempts] = useState('')
  const [activeKey, setActiveKey] = useState(0)
  const dispatch = useDispatch();
  const t = useTranslations();
  let courseId = localStorage.getItem('courseId')
  const [quizAttemptsCount, setQuizAttemptsCount] = useState('');
  const user = useSelector((state) => state.user);
  const { settingsData } = useSettings(user);

  useEffect(() => {
    if (location?.state?.pageType === "Instruction") {
      dispatch(
        courseResultByCourseId(location?.state?.slug, location?.state?.courseId)
      ).then((data) => {
        setCourseResultData(data?.payload?.data);
      });
    } else {
      dispatch(courseResult(location?.state?.slug ? location?.state?.slug : location?.state?.courseId, courseId)).then((data) => {
        setCourseResultData(data?.payload?.data);
      });
    }
  }, []);

  useEffect(() => {
    const quizAttemptsValue = settingsData?.data?.[0] ? settingsData?.data?.[0]?.customization?.quizAttempts : 3;
    setQuizAttemptsCount(quizAttemptsValue);
  },[settingsData]);
  return (
    <>
      <Box className="header-title">
        {/* {courseResultData?.courseName} */}
        <Box>
          {t('Overall_Performance')}
        </Box>
        <Box className="sub-heading-result">
          {t('An overview of critical performance indicators and results')}
        </Box>
      </Box>
      <Grid container spacing={2} xs={12} lg={12}>
        <Grid item xs={12} lg={9}>
          {courseResultData && <ScoreCard courseResultData={courseResultData} quizAttemptsCount={quizAttemptsCount}/>}
          {courseResultData && (
            <DistributionCards
              courseResultData={courseResultData}
              setActiveAttempts={setActiveAttempts}
              setActiveKey={setActiveKey}
              activeAttempts={activeAttempts}
              activeKey={activeKey}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={3} className="data-grid" style={{ paddingBottom: '31px' }}>
          {courseResultData && activeAttempts && <QuestionDistribution courseResultData={courseResultData} activeAttempts={activeAttempts} activeKey={activeKey} />}
        </Grid>
 
 
      </Grid>
      {courseResultData && <AttemptCard courseResultData={courseResultData} />}
 
    </>
  );
}
 
export default Reports;
