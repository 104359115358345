import { getUserSupportTickets } from "../services/feedbackService";

export const getUserSupportTicketsAction = (userId) => (dispatch) => {
    dispatch(request(userId));
    return getUserSupportTickets(userId)
        .then((response) => {
            // handle success
            dispatch(success(response));
            return response;
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "USER_TICKETS_REQUEST" };
    }

    function success(response) {
        return { type: "USER_TICKETS_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "USER_TICKETS_FAILURE", payload: error };
    }
};

