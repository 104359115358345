import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import hn from "./locales/hn.json";
import as from "./locales/as.json";
import bn from "./locales/bn.json";
import gu from "./locales/gu.json";
import kn from "./locales/kn.json";
import ml from "./locales/ml.json";
import mr from "./locales/mr.json";
import ory from "./locales/ory.json";
import pa from "./locales/pa.json";
import ta from "./locales/ta.json";
import te from "./locales/te.json";
import ur from "./locales/ur.json";

// the translations
const resources = {
    en: {
        translation: en, // English
    },
    hn: {
        translation: hn, // Hindi
    },
    as: {
        translation: as, // Assamese
    },
    bn: {
        translation: bn, // Bengali
    },
    gu: {
        translation: gu, // Gujarati
    },
    kn: {
        translation: kn, // Kannada
    },
    ml: {
        translation: ml, // Malayalam
    },
    mr: {
        translation: mr, // Marathi
    },
    ory: {
        translation: ory, // Odia
    },
    pa: {
        translation: pa, // Punjabi
    },
    ta: {
        translation: ta, // Tamil
    },
    te: {
        translation: te, // Telugu
    },
    ur: {
        translation: ur, // Urdu
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
