import status from '../constants/actionStatus';

export default (state = {
    notification: [],
    notificationCount: 0
}, action = {}) => {
    switch (action.type) {
    case 'GET_NOTIFICATION_REQUEST':
        return {
            status: status.PENDING,
            notification: [],
            notificationCount: state.notificationCount
        };
    case 'GET_NOTIFICATION_SUCCESS':
        const combinedNotifications = [ ...state?.notification, ...action?.notificationList?.data ];
        const uniqueNotifications = Array.from(new Set(combinedNotifications.map(JSON.stringify))).map(JSON.parse);
        return {
            status: status.SUCCESS,
            notification: uniqueNotifications,
            notificationCount: action?.notificationList?.unreadNotificationsCount || 0
        };
    case 'COURSE_ADDED_NOTIFICATION_SUCCESS':
        return {
            ...state,
            notification: [ action.notificationRes, ...state.notification ],
            notificationCount: state.notificationCount + 1
        };
    case 'GET_NOTIFICATION_FAILURE':
        return {
            status: status.ERROR,
            notification: state.notification
        };
    default:
        return state;
    }
};
