import { Box, Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import DefaultCourseImage from "../../../assets/images/course-image.jpg"
import trashIcon from "../../../assets/images/trash-icon.png"
import redTrash from "../../../assets/images/red-trash.png"

function NotificationItem({ item, deleteNotificationById, settingsData }) {
    const [isHovered, setIsHovered] = useState(false);
    const currentTime = Date.now();
    const scheduledDate = Date.parse(item?.updated?.on);
    const differenceInMilliseconds = Math.abs(currentTime - scheduledDate);
    const differenceInSeconds = differenceInMilliseconds / 1000;
    const differenceInMinutes = differenceInSeconds / 60;
    const differenceInHours = differenceInMinutes / 60;
    const differenceInDays = differenceInHours / 24;

    let timeAgo;
    if (differenceInMinutes < 60) {
        timeAgo = `${Math.floor(differenceInMinutes)} min ago`;
    } else if (differenceInHours < 24) {
        timeAgo = `${Math.floor(differenceInHours)} h ago`;
    } else {
        timeAgo = `${Math.floor(differenceInDays)} d ago`;
    }
    return (
        <Box
        className={`notification-detail-items ${
            item?.isRead ? "" : "notification-unread"
        }`}
        >
        {!item?.isRead && <span className="notification-dot" />}
            <Box className="notification">
                <img className="notification-detail-profile-pic" src={item?.notificationId?.image || settingsData?.data?.[0]?.branding?.courseImage?.imagePath ? item?.notificationId?.image || settingsData?.data?.[0]?.branding?.courseImage?.imagePath : DefaultCourseImage} alt="icon"/>
                <Box className="notification-text">
                    <Box className="notification-list-content">
                        <Typography className="notification-detail-heading">
                            {item?.notificationId?.title}
                        </Typography>
                        <Typography className="notification-detail-info">
                            {item?.notificationId?.message}
                        </Typography>
                    </Box>
                    <Box className="notification-detail-time-delete">
                        <Typography className="notification-detail-info notification-time-text">
                            {timeAgo}
                        </Typography>
                    <Button className='notification-delete' 
                            onClick={()=>deleteNotificationById(item?.notificationId?._id)}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                    >
                        <img src={isHovered ? redTrash : trashIcon} alt="Delete"/>
                    </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default NotificationItem;