import React, { useEffect, useState } from "react";
import InfoCard from "./cards";
import CertificateCard from "./certificate-card";
import CurrentCourse from "./current-course";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom";
import { useSettings, useTranslations } from "examsbook-react-sdk/custom-hooks";
import CommonButton from "../../constants/CommonButton";
import BannerCard from "./BannerCard";
import CourseSideInfo from "./CourseSideInfo";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  mainDiv: (isFirefox) => ({
    paddingBottom: isFirefox ? "10px" : "80px",
    paddingTop: "20px",
    "@media (max-width: 767px)": {
      paddingBottom: 0,
    },
  }),
  mainBox: {
    padding: "0 8px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "-8px 0",
    "@media (max-width: 959px)": {
      flexDirection: "column",
      width: "100%",
      margin: 0,
      padding: "0 8px",
    },
  },
  okButton: {
    borderRadius: "4px",
    padding: "6px 30px",
    fontSize: "15px",
    margin: "15px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0c3450e0 !important",
    },
  },
  modalBg: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100% !important",
    background: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "999999",
  },
  modalContent: {
    padding: "20px",
    width: "27%",
    marginTop: "15%",
    marginLeft: "35%",
    "@media (min-width:280px) and (max-width:500px)": {
      width: "55% !important",
      marginTop: "25%",
      marginLeft: "15%",
    },
    "@media (max-width:1024px)": {
      width: "35%",
    },
  },
  modalHeading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: "6px",
    padding: "20px 40px",
  },
  modalText: {
    fontWeight: "normal",
    textAlign: "center",
  },
}));

function NewDashboard() {
  const location = useLocation();
  const history = useHistory();
  const quizMode = localStorage.getItem("quizMode");
  const slug = localStorage.getItem("slug");
  const [courseData, setCourseData] = useState(null);
  const [categorySelect, setCategorySelect] = useState("assigned");
  const t = useTranslations();
  const [showAlert, setShowAlert] = useState(false);
  const isFirefox = typeof InstallTrigger !== "undefined";
  const classes = useStyles(isFirefox);
  const user = useSelector((state) => state.user);
  const { settingsData } = useSettings(user);

  const openModal = () => {
    setShowAlert(true);
  };

  const closeModal = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("selectedTab");
    };
  }, []);

  useEffect(() => {
    if (location?.state?.params === `/quiz/${quizMode}/${slug}`) {
      return () => {
        history.replace("/my-courses", { params: window.location.pathname });
      };
    }
  }, []);

  useEffect(() => {
    let siteUrl = window.location.href.split("?");
    let queryParams = siteUrl[1];
    const popUpShown = localStorage.getItem("showAlert");
    if (!popUpShown) {
      if (queryParams === "valid=false") {
        openModal();
        localStorage.setItem("showAlert", "true");
      }
    }
  }, []);

  const AlertMessage = ({ showModal, closeModal }) => {
    return (
      <Box
        className={classes.modalBg + ` ${showModal ? "show" : ""}`}
        onClick={closeModal}
      >
        <Box
          className={classes.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <Box className={classes.modalHeading}>
            <h2 className={classes.modalText}>
              {t("Unauthorized user to access admin portal!")}
            </h2>
            <CommonButton
              onClick={closeModal}
              title={t("Ok")}
              classStyleHeading={classes.okButton}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.mainDiv}>
        <BannerCard />
        <Grid container spacing={2} className={classes.mainBox}>
          {showAlert && (
            <AlertMessage showModal={showAlert} closeModal={closeModal} />
          )}
          <Grid item xs={12} md={8} sm={12}>
            <InfoCard
              setCourseData={setCourseData}
              setCategorySelect={setCategorySelect}
            />
            <CertificateCard />
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <CourseSideInfo
              courseCount={courseData}
              selectedCategory={categorySelect}
              settingsData={settingsData}
            />
          </Grid>
        </Grid>
        <CurrentCourse />
      </Box>
    </>
  );
}

export default NewDashboard;
