import React from "react";
import NotificationItem from "./NotificationItem";

function NotificationView (props) {
    return (
        props?.notifications.map((item, index)=> {
            return <NotificationItem index={index} item={item} deleteNotificationById={props.onDeleteNotification} settingsData={props?.settingsData} />
        })
    );
}

export default NotificationView;