const initialState = {
    subdomain: localStorage.getItem("subdomain") || null,
  };
  
  const subdomainReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_SUBDOMAIN":
        return { ...state, subdomain: action.payload };
  
      case "CLEAR_SUBDOMAIN":
        localStorage.removeItem("subdomain");
        return { ...state, subdomain: null };
  
      default:
        return state;
    }
  };  