import status from "../constants/actionStatus";

export default (
    state = {
        settingsData: {},
    },
    action = {}
) => {
    switch (action.type) {
        case "GET_SETTINGS_REQUEST":
            return {
                status: status.PENDING,
                settingsData: {},
            };

        case "GET_SETTINGS_SUCCESS":
            return {
                status: status.SUCCESS,
                settingsData: action.payload?.data ? action.payload : state.settingsData, // Store only if payload is valid
            };

        case "GET_SETTINGS_FAILURE":
            return {
                status: status.ERROR,
                settingsData: state.settingsData, // Keep previous state if error occurs
            };
        default:
            return state;
    }
};
