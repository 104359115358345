import axios from "./axiosConfig";

export function microsoftLogin(data) {
  return axios
    .post("/api/v1/microsoftMobileLogin", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
