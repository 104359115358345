import { useDispatch, useSelector } from "react-redux";
import i18n from "examsbook-react-sdk/translations";
import { updateUser } from "examsbook-react-sdk/actions/getUser";

/**
 * Hook to set default language of user.
 * this returns language and setter.
 * @returns {{defaultLanguage: (*|string), setUserLanguage: setUserLanguage}}
 */
export default function useUserLanguage() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const storedLanguage = localStorage.getItem("language");
    const language = storedLanguage !=="null" ? storedLanguage : user.language;

    function setUserLanguage(lang) {
        if (!lang) return;
        localStorage.setItem("language", lang);
        i18n.changeLanguage(lang);
        dispatch(updateUser(user._id, { language: lang }));
    }

    return { language, setUserLanguage };
}
