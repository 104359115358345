import React, { useEffect, useState } from 'react';
import '../CoursesList/coursesList.scss';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import {Typography, Box, makeStyles, LinearProgress} from '@material-ui/core';
import { ReactComponent as Live } from "../../../src/assets/images/live.svg";
import { recentCourseAction } from 'examsbook-react-sdk/actions';
import { useDispatch, useSelector } from 'react-redux';
import OverlayButton from '../../constants/OverlayButton';
import { courseAddedNotification } from 'examsbook-react-sdk/actions/getNotifications';
import moment from "moment";
import { ReactComponent as ExpiringClock } from "../../../src/assets/images/expiringclock.svg";
import { ReactComponent as CertifiedIcon } from "../../../src/assets/images/certifiedIcon.svg";
import { useTheme } from '../../constants/themeProvider';
import DefaultCourseImage from "../../../src/assets/images/course-image.jpg"

/**
 * My Courses card
 * @param props
 * @returns {*}
 * @constructor
 */
function MyCoursesCard(props) {
    const { testData, certificateListing, dueDateinDays, settingsData } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const t = useTranslations();
    const percentageCompletion = testData?.progressPercent !== undefined ? testData?.progressPercent : 0
    const [certificateData, setCertificateData] = useState('')

    const [expireDays, setExpireDays] = useState(false);
    const [courseExpired, setCourseExpired] = useState(false);
    const user = useSelector((state) => state.user);
    let userId = user?._id;
    const [courseExpiryDate, setCourseEndDate] = useState();
    const [showComponent, setShowComponent] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        checkExpiration();
    }, []);

    useEffect(() => {
        getCertificateListing();
    },[testData, certificateListing])

    const checkExpiration = () => {
        const currentDate = new Date();
    
        // Determine the course end date
        let courseEndDate = testData?.endDate 
            ? new Date(testData.endDate) 
            : testData?.assignedCourseEndDate 
                ? new Date(testData.assignedCourseEndDate) 
                : null;

        // Set course expiry date if available
        if (courseEndDate) {
            setCourseEndDate(courseEndDate);
            const timeDifference = courseEndDate.getTime() - currentDate.getTime();
            setExpireDays(Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));
        } else {
            // If no end date is available, set expiration days to null
            setExpireDays(null);
        }
    };

    useEffect(()=>{
        if (expireDays > 0) {
            setCourseExpired(false)
        } else{
            setCourseExpired(true)
        }
    },[expireDays])

    useEffect(() => {
        const timer = setTimeout(() => {
          setShowComponent(true);
        }, 200);
        return () => clearTimeout(timer);
    }, []);

    const callRecentApi = async () => {
        const payload = { courseId: testData?.courseId?._id };
        await dispatch(recentCourseAction(payload));
    };

    function handleGoToTestClick() {
        localStorage.setItem('dataKey', testData?.courseId?._id);
        window.dispatchEvent(new Event('storage'));
        reactGaEvent("Course Material", "Go To Course");
        callRecentApi()
        var oldData = localStorage.getItem('testData');
        var recentCourseData = oldData ? JSON.parse(oldData) : [];
        var isDataExist = false;

        recentCourseData && recentCourseData.map((item) => {
            if (item.courseId === testData?.courseId?._id) {
                isDataExist = true;
            }
        })

        if (isDataExist !== true) {
            recentCourseData = [{ courseId: testData?.courseId?._id, subjectId: '', topicId: '', subjectDetail: testData, subjectName: '', topicName: '' }, ...recentCourseData]
        }
        if (recentCourseData.length === 4) {
            recentCourseData.pop(3);
        }
        const filteredArray = recentCourseData.filter(item => item.courseId === testData?.courseId?._id);
        localStorage.setItem('filteredArray', JSON.stringify(filteredArray));
        localStorage.setItem('courseName', filteredArray[0]?.subjectDetail?.courseId?.name);
        localStorage.setItem('courseDescription', filteredArray[0]?.subjectDetail?.courseId?.description);
        localStorage.setItem('testData', JSON.stringify(recentCourseData));
        localStorage.setItem('courseId', testData?.courseId?._id);
        history.push('/course-material/content', { courseId: testData._id, subjectId: '', topicId: '', subjectDetail: testData, subjectName: '', topicName: '' });
    }

    useEffect(() => {
        return () => {
            localStorage.removeItem('selectedTab');
        };
    }, [])

    const getCertificateListing = () => {
        const isCourseIdPresent = certificateListing?.filter(item => item?.courseId?._id === testData?.courseId?._id);
        if (isCourseIdPresent) {
            setCertificateData(certificateListing?.find(item => item?.courseId?._id === testData?.courseId?._id));
        }
    }

    return (
      <Box className="card-list-image">
            <Box className='image-container image-fix'>
                <img style={{objectFit: 'contain', borderRadius: '4px 4px 0px 0px'}}
                     src={ testData?.featured || settingsData?.data?.[0]?.branding?.courseImage?.imagePath
                             ? testData?.featured || settingsData?.data?.[0]?.branding?.courseImage?.imagePath
                              : DefaultCourseImage
                                        }
                    alt="" onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = DefaultCourseImage;
                    }} />
            </Box>
        <Box className='card-list'>
            <Box className="course-module">
                {testData?.isSelfLearning === true ? <button className="course-learning" style={{border: `1px solid ${theme?.primaryColor}`, color: theme?.primaryColor}}>{t('SELF LEARNING')}</button> : ''}
                {testData.isLive === true ? <button className="course-live-learning" style={{border: `1px solid ${theme?.primaryColor}`, color: theme?.primaryColor}}><span className='live-image'><Live/></span><span>{t('live')}</span></button> : ''}
            </Box>
           <p className='card-title'>{testData?.courseId?.name}</p>
            <p className='card-description' dangerouslySetInnerHTML={{ __html: testData?.courseId?.description }}></p>
            <Box className="course-bottom-right-expire">
                <Box className="my-course-button-expire">
                    {showComponent && <Box style={{display: 'flex', marginTop: '10px'}}><ExpiringClock/></Box>}
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                    {showComponent && <span className={`ribbon-text-expire`} style={{maxWidth: certificateData?.courseId?._id !== testData?.courseId?._id ? '30vw' : '15vw', marginTop: '10px'}}>{expireDays > 0 ? `${t('Expiring on')} ${moment(testData?.endDate).format("DD-MM-YYYY")}` : t('Expired')}</span>}
                    </Box>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', minHeight: '34px'}}>
                {certificateData?.courseId?._id === testData?.courseId?._id &&
                    <Box style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                        {showComponent && <> <Box style={{display: 'flex'}}><CertifiedIcon/></Box>
                        <Box style={{display: 'flex', alignItems: 'center'}}><span className='certified-text'>{t('Qualified')}</span></Box></>}
                    </Box>}
                </Box>
            </Box>
            <Box className='card-title-progress' style={{ minHeight: '26px' }}>
                <LinearProgressWithLabel value={percentageCompletion} />
            </Box>

            <Box className="card-btn-new">
                <OverlayButton onClick={handleGoToTestClick} disabled={courseExpired} title={t('VIEW_COURSE')}/>
            </Box>
        </Box>
      </Box>
    );
}
function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box mr={1} className='linearProgressDiv'>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" style={{ color: '#0C3450' }}>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export { MyCoursesCard, LinearProgressWithLabel };
