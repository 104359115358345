import axios from './axiosConfig';

export function saveFeedbacksForm(data) {
    return axios.post('/api/v1/feedback', data)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            // handle error
            return error;
        });
}

export function getUserSupportTickets(userId) {
    return axios
        .get(`/api/v1/user-support-tickets?userId=${userId}`)
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error.data;
        });
}

